import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDFRm6UGEraY4MYIIzduZx9FYLturWgUaw",
  authDomain: "teen-patti-48f2b.firebaseapp.com",
  projectId: "teen-patti-48f2b",
  storageBucket: "teen-patti-48f2b.appspot.com",
  messagingSenderId: "852985076685",
  appId: "1:852985076685:web:027bc8cd2c6e822c40c284",
  measurementId: "G-L5077X4TPK",
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
