import React, { useState, useEffect } from "react";
import Style from "./redeem.module.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import {
  getCollectionData,
  getOrderByCollectionData,
} from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  TablePagination,
  CircularProgress,
  Box,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
const headCells = [
  { id: "sno", label: "Sr.No" },
  { id: "title", label: "Title" },
  { id: "coin", label: "Coin" },
  { id: "amount", label: "Amount" },
  { id: "image", label: "Image" },
  { id: "date", label: "Added Date" },
  { id: "action", label: "Action" },
];

export const ReedemManagement = () => {
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [loader, setLoader] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

 
  // const handleSort = (property) => {
  //   const isAsc = orderBy === property && order === "desc";
  //   setOrderBy(property);
  //   setOrder(isAsc ? "asc" : "desc");

  //   const sortedData = [...tableData].sort((a, b) => {
  //     const aValue = a[property] || 0; // Assume a default value if undefined
  //     const bValue = b[property] || 0; // Assume a default value if undefined

  //     return isAsc ? aValue - bValue : bValue - aValue;
  //   });

  //   setTableData(sortedData);
  // };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrderBy(property);
    setOrder(isAsc ? "asc" : "desc");
  
    const sortedData = [...tableData].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];
  
      if (property === "name" || property === "title") {
        // Use localeCompare for string comparison
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
  
      // For numeric sorting, use the default comparison
      return isAsc ? aValue - bValue : bValue - aValue;
    });
  
    setTableData(sortedData);
  };
  

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearchChange = (event) => {
    const inputsValue = event.target.value.trim();
    if (inputsValue == "") {
      setSearchTerm(inputsValue);
    } else {
      setSearchTerm(event.target.value);
    }
  };

  const handleDelete = (id) => {
    setLoader(true);
    deleteDocument("RedeemManagement", id)
      .then(() => {
        console.log(`Document with id ${id} deleted successfully.`);
        handleSnackbarOpen(`Deleted successfully`);

        fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id ${id}:`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
    console.log(id);
  };
  const handleEdit = (res) => {
    navigate(`/reedemNow/addredeem/${res.id}`);
  };
  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  const fetchData = async () => {
    // const data = await getCollectionData('RedeemManagement');
    let data = await getOrderByCollectionData(
      "RedeemManagement",
      "date",
      "desc"
    );
    setTableData(data);
    setLoader(false);
    console.log(data, "all data");
  };

  // const filteredData = tableData?.filter((item) => {
  //   console.log("Item values:", Object.values(item));

  //   return Object.values(item).some(
  //     (value) =>
  //       typeof value === "string" &&
  //       value.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });
  const filteredData = tableData?.filter((item) => {
    const title = item.hasOwnProperty("title") ? item.title : "";
    const coin = item.hasOwnProperty("coin") ? item.coin : "";
    const amount = item.hasOwnProperty("amount") ? item.amount : "";

    const lowerCaseTitle = title.toLowerCase();
    const lowerCaseCoin = coin.toString().toLowerCase(); // Convert to string before toLowerCase()
    const lowerCaseAmount = amount.toString().toLowerCase();

    return (
      lowerCaseTitle.includes(searchTerm.toLowerCase()) ||
      lowerCaseCoin.includes(searchTerm.toLowerCase()) ||
      lowerCaseAmount.includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
      <div className="mainHeading">
        <h4 className="tableHeading">Redeem List</h4>
        <button
          className="custom-btn"
          onClick={() => navigate("/reedemNow/addredeem/add")}
        >
          {" "}
          Add Redeem{" "}
        </button>
      </div>

      <div
        className="w-100  mb-3"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <label htmlFor="" className="labelCss">
          Search 
        </label>
        <input
          className="searchInput "
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <TableContainer component={Paper}>
        <Table className="table_background">
          <TableHead>
            <TableRow className="tableHeading">
              {headCells.map((headCell) => (
                <TableCell
                  className="tableHead"
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    // active={orderBy === headCell.id}
                    active={ headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleSort(headCell.id)}
                  >
                    <b>{headCell.label}</b>
                  </TableSortLabel>
                  
                </TableCell>
              ))}
            </TableRow>
            
          </TableHead>
          <TableBody>
            {filteredData &&
              filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  const srNo = calculateSrNo(index);
                  const formattedDate = item.date
                    .toDate()
                    .toLocaleDateString("en-GB");
                  return (
                    <>
                      <TableRow className="">
                        <TableCell>{srNo}</TableCell>
                        <TableCell style={{ fontFamily: "sans-serif" }}>
                          {item.title}
                        </TableCell>
                        <TableCell>{item.coin}</TableCell>
                        <TableCell>{item.amount}</TableCell>
                        <TableCell>
                          <img
                            src={item.image}
                            className={Style.imagesize}
                          ></img>
                        </TableCell>
                        {/* <TableCell>{item.date.toDate().toLocaleDateString('en-GB')}</TableCell> */}
                        <TableCell>{formattedDate}</TableCell>
                        <TableCell>
                          <div className={Style.icondiv}>
                            <button
                              className={Style.EditIcon}
                              onClick={() => handleEdit(item)}
                              title="Edit"
                            >
                              <EditIcon />
                            </button>

                            <button
                              className={Style.delete_icon}
                              title="Delete"
                              onClick={() => handleDelete(item.id)}
                            >
                              <DeleteIcon />
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
          </TableBody>
        </Table>
        {(!filteredData || filteredData.length === 0) && 
      <div className="text-center p-3 "style={{ display: loader ? "none" : "block" , backgroundColor:"#f5f7ff"}}>   No data available
      </div>}


  
      </TableContainer>
     
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
};
