import React, { useRef, useState } from "react";
import Style from "./ChipsManagment.module.css";
import { useNavigate } from "react-router";
import { addDocument } from "../../firebaseAuth/cloudFirestore/setData";
import {
  Box,
  CircularProgress,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
export const AddChipsManagement = () => {
  const [loader, setLoader] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [data, setData] = useState();
  const [inputTerm, setInputTerm] = useState();
  const ChipsData = useRef({
    coin: "",
    price: "",
    date: new Date(),
  });
  const handleInputChange = (e) => {
    setInputTerm((ChipsData.current.coin = e.target.value));
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(!snackbarOpen);

    setSnackbarMessage(message);
    return;
  };
  const navigate = useNavigate();

  const AddChips = async (e) => {
    e.preventDefault();
    setLoader(true);
    let a = await addDocument("Chips", ChipsData.current);
    //    console.log(a)
    setData(a);
    handleSnackbarOpen(`Added successfully`);
    setTimeout(() => {
      navigate("/chips");
    }, 1000);
  };

  return (
    <>
      {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
      <div className={Style.AdduserBackground}>
        <div>
          <span className={`tableHeading`}>Add Chips</span>
        </div>
        <form onSubmit={AddChips}>
          <div className={`px-3 pt-3 pb-3 mt-3  ${Style.inputField}`}>
            <div className="d-flex  ">
              <div className={Style.labelName}>
                <label for="inputPassword" className="col-sm-12 col-form-label">
                  {" "}
                  Coin *
                </label>
              </div>
              <div className={Style.NumberInput}>
                <input
                  type="number"
                  className="form-control"
                  id="inputPassword"
                  required
                  onChange={(e) => {
                    if (e.target.value === " ") {
                      e.target.value = "";
                    } else {
                      handleInputChange(e);
                    }
                  }}
                  value={inputTerm}
                />
              </div>
            </div>
            <div className="d-flex pt-3">
              <div className={Style.labelName}>
                <label for="inputPassword" className="col-sm-12 col-form-label">
                  Price*
                </label>
              </div>
              <div className={Style.NumberInput}>
                <input
                  type="number"
                  className="form-control"
                  id="inputPassword"
                  required
                  onChange={(e) => {
                    const enteredValue = parseFloat(e.target.value);
                    const positiveValue = isNaN(enteredValue)
                      ? ""
                      : Math.max(enteredValue, 0);
                    ChipsData.current.price = positiveValue;
                    e.target.value = positiveValue;
                  }}
                />
              </div>
            </div>

            <div className={Style.FormButton}>
              <button className={Style.Submitbutton}>Submit</button>
              <button
                className={Style.cencelbutton}
                onClick={() => navigate("/chips")}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "green" }}
            message={snackbarMessage}
          />
        </Snackbar>
      </div>
    </>
  );
};
