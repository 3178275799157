import React, { useEffect, useState } from 'react'
import Style from "./welcome.module.css";
import { useNavigate } from "react-router";
import  {useParams} from "react-router-dom";
import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";
import { updateDocument } from "../../firebaseAuth/cloudFirestore/updateData";
import { Snackbar, SnackbarContent } from '@mui/material';




export default function EditWelcomeBonus() {

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");

    const handleSnackbarOpen = (message) => {
        setSnackbarOpen(!snackbarOpen);
    
        setSnackbarMessage(message);
        return;
      };


    const [addData, setAddData] =useState({
        day:"",
        coin: "",
        gameplay:""
    })
    // console.log(addData)

    
    const welcomeBonus = async () => {
        try {
  
            const response = await getDocumentData("WelcomeBonus", id);
            console.log(response, "kkk");
            const { day, coin, gameplay } = response;
        
        
            setAddData({
                day: day|| "",
                coin: coin || "",
                gameplay : gameplay || ""
              
            });
        } catch (error) {
            console.error("Error fetching document for edit:", error);
        }
    }


    const { id } = useParams();
    const previousPage = useNavigate();

    const handleClick = (e) =>{

        setAddData({
            ...addData,
            [e.target.id]:e.target.value
        })

    }
    const handleSubmit = async(e) => {
        e.preventDefault();
        handleSnackbarOpen(`Updated successfully`);

     
    
        try {
            await updateDocument("WelcomeBonus", id, addData);
    
          previousPage("/welcomebonus");
    
          setAddData({
            day:"",
            coin: "",
            gameplay:""
         
          });
        } catch (error) {
          console.error("Error submitting the form:", error);
        }
      };

    
    console.log(id,"id Check")
    useEffect(()=>{
       welcomeBonus();
    },[]);
  return (
    <>
    
    <div className={Style.AdduserBackground}>
                <div>
                    <h4 className={'tableHeading'}> Edit Welcome Bonus
                    </h4>
                </div>
                <div className={` pt-4 pb-4 mt-3  ${Style.inputField}`}>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="p-2">
                            <div className='d-flex  '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Day*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="text" className="form-control " id="day" disabled onChange={handleClick}
                                        value={addData.day}
                                        required />
                                </div>
                            </div>
                            <div className='d-flex pt-3'>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Game Play Needed*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="number" className="form-control" id="gameplay" onChange={handleClick}
                                        value={addData.gameplay}
                                        required />
                                </div>
                            </div>
                            <div className='d-flex pt-3 '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Coin*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="number" className="form-control" id="coin" onChange={handleClick}
                                        value={addData.coin}
                                        required />
                                </div>
                            </div>

                            <div className={Style.FormButton}>
                                <button className="custom-btn" type="submit" >Update</button>
                                <button className='cancel_btn ms-2' onClick={() => previousPage("/welcomebonus")}>Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <SnackbarContent
                    style={{ backgroundColor: "#60bf86" }}
                    message={snackbarMessage}
                />
            </Snackbar></>
  )
}
