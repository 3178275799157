import React, { useState, useEffect } from "react";
import Style from "./welcome.module.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import { getCollectionData } from "../../firebaseAuth/cloudFirestore/getData";


import { useParams } from "react-router-dom"

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
  TablePagination,
  CircularProgress,
  Box,
} from "@mui/material";
const headCells = [
  { id: "day", label: "Day" },
  { id: "coin", label: "Coin" },
  { id: "gameplay", label: "Game Play Needed" },
  { id: "action", label: "Action" },

];

export const WelcomeBonus = () => {
  const [loader, setLoader] =useState(true)
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [tableData, setTableData] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const fetchData = async () => {
    const data = await getCollectionData("WelcomeBonus");
    setTableData(data);
    setLoader(false)

  };
  console.log(tableData, "check")
  const handleDelete = (e) => {

  }
  const handleEdit = (res) => {

    console.log(res.day, "tttt")
    navigate(`/editBonus/${res.id}`)

  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrderBy(property);
    setOrder(isAsc ? "asc" : "desc");
  
    const sortedData = [...tableData].sort((a, b) => {
      const aValue = a[property] || 0; // Assume a default value if undefined
      const bValue = b[property] || 0; // Assume a default value if undefined
  
      return isAsc ? aValue - bValue : bValue - aValue;
    });
  
    setTableData(sortedData);
  };
  const filteredData = tableData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();

  }, []);

  return (
    <>
        {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div className="mainHeading">
        <h4 className="tableHeading">Welcome Bonus</h4>
        {/* <button
            className="custom-btn"
            onClick={() => navigate("/addredeem")}
          >
            {" "}
            Add{" "}
          </button> */}
      </div>

      <div
        className="w-100  mb-3"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <label htmlFor="" className="labelCss">
          Search
        </label>
        <input
          className="searchInput "
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
        />

      </div>
      <TableContainer component={Paper}>
        <Table className="table_background">
          <TableHead>
            <TableRow className="tableHeading">
              {headCells.map((headCell) => (
                <TableCell className="tableHead"
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    // active={orderBy === headCell.id}
                    active={ headCell.id}

                    direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => handleSort(headCell.id)}
                  >
                    <b>{headCell.label}</b>
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {
              filteredData &&
              filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  // const srNo = calculateSrNo(index);
                  const formattedDate = new Date(
                    item?.date
                  ).toLocaleDateString("en-GB");
                  return (
                    <>
                      <TableRow className="">

                        <TableCell style={{ fontFamily: "sans-serif" }}>{item.day}</TableCell>
                        {/* <TableCell><img src={item.coin} className={Style.imagesize}></img></TableCell> */}
                        <TableCell>{item.coin}</TableCell>
                        <TableCell>{item.gameplay}</TableCell>

                        {/* <TableCell>{formattedDate}</TableCell> */}
                        <TableCell>  <div className={Style.icondiv}>
                          <button
                            className={Style.EditIcon}
                            onClick={() => handleEdit(item)}
                            title="Edit"
                          >
                            <EditIcon />
                          </button>

                          {/* <button className={Style.EditIcon} onClick={() => handleDelete(item.id)}>
                              <DeleteIcon />
                            </button> */}
                        </div>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
            }

          </TableBody>
        </Table>
        {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loader ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  )
};
