import style from "./AdminCoin.module.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";

import {
  getCollectionData,
  getMatchingData,
  getOrderByCollectionData,
} from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { type } from "@testing-library/user-event/dist/type";

/*data for first table*/

const headCellsone = [
  { id: "srno", label: "Sr No" },
  { id: "id", label: "User Id" },
  { id: "name", label: "User Name" },
  { id: "tableid", label: "Table Id" },
];

const dataone = [
  { srno: 1, id: 234, name: "ankit", tableid: 443 },
  { srno: 2, id: 8548, name: "usman", tableid: 1343 },
  { srno: 3, id: 23454, name: "manan", tableid: 2683 },
  { srno: 4, id: 6454, name: "raj", tableid: 440933 },
  { srno: 5, id: 34654, name: "nupura raj", tableid: 265633 },
  { srno: 6, id: 5443, name: "himanshu", tableid: 5400633 },
  { srno: 7, id: 8484, name: "paras", tableid: 2325753 },
  { srno: 8, id: 34387, name: "sikhar", tableid: 1245643 },
  { srno: 9, id: 784, name: "NUPURA RAJ", tableid: 1254543 },
  { srno: 10, id: 48548, name: "izaz", tableid: 5433454 },
  { srno: 11, id: 999, name: "rajan", tableid: 245573 },
  { srno: 12, id: 1, name: "aakash", tableid: 123755 },

  // Add more data as needed
];
console.log(dataone, "check");
// console.log(dataone.slice().reverse())

/*data for second table*/

const data = [
  { id: 999, name: "John", gamecoin: 30, commisioncoin: 50000 },
  { id: 998, name: "usman", gamecoin: 30, commisioncoin: 50000 },
  { id: 6, name: "ankti", gamecoin: 30, commisioncoin: 50000 },
  { id: 4, name: "deep", gamecoin: 30, commisioncoin: 50000 },
  { id: 16, name: "raaj", gamecoin: 30, commisioncoin: 50000 },
  { id: 1345, name: "santosh", gamecoin: 30, commisioncoin: 50000 },
  { id: 143, name: "gnaesh", gamecoin: 30, commisioncoin: 50000 },
  { id: 1354, name: "ram", gamecoin: 30, commisioncoin: 50000 },
  { id: 997, name: "yogesh", gamecoin: 30, commisioncoin: 50000 },
  { id: 154, name: "arun", gamecoin: 30, commisioncoin: 50000 },
  { id: 17, name: "divya", gamecoin: 30, commisioncoin: 50000 },
  { id: 1443, name: "ambujh", gamecoin: 30, commisioncoin: 50000 },
  { id: 1656, name: "ankush", gamecoin: 30, commisioncoin: 50000 },

  // Add more data as needed
];

const headCells = [
  { id: "no", label: "Sr No" },
  { id: "id", label: "Game Id" },
  { id: "name", label: "Winner Name" },
  { id: "gamecoin", label: "Game Coin" },
  { id: "commisioncoin", label: "Commission Coin" },
];

export const AdminCoin = () => {
  const [sortColumnsone, setSortColumnsone] = useState({
    // no: "asc",
    id: "asc",
    name: "asc",
    tableid: "asc",
  });

  /*states for first table */
  const [searchTermone, setSearchTermone] = useState("");
  const [orderByone, setOrderByone] = useState("id");
  const [orderone, setOrderone] = useState("asc");
  const [tableDataone, setTableDataone] = useState(dataone);
  const [pageone, setPageone] = useState(0);
  const [rowsPerPageone, setRowsPerPageone] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [expandedRow, setExpandedRow] = useState(null);

  /*states for second table */

  const [sortColumns, setSortColumns] = useState({
    // no: "asc",
    id: "asc",
    name: "asc",
    gamecoin: "asc",
    commisioncoin: "asc",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("id");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState(data);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  /*functions for first table */

  // useEffect(() => {
  //   const fetchDataAndStopLoader = async () => {
  //     try {
  //       await fetchData();
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchDataAndStopLoader();
  // }, []);

  const handleSearchChangeone = (event) => {
    const inputValue = event.target.value.trim();

    if (inputValue == "") {
      setSearchTermone(inputValue);
    } else {
      setSearchTermone(event.target.value);
    }
  };

  // const handleSortone=(property)=>{
  //   const isAsc = sortColumnsone[property] === "desc";
  //   setSortColumnsone((prevSortColumnsone)=>({
  //     ...prevSortColumnsone,
  //     [property]: isAsc ? "asc" : "desc",
  //   }));

  //   // console.log(tableDataone,"NJfdjfidjf");

  //   const sortedDataone= tableDataone.slice().sort((a,b)=>{
  //     const valueA=a[property];
  //     const valueB=b[property];

  //     if(property==="date"){
  //       return isAsc? valueA-valueB : valueB - valueA;
  //     }
  //     else{
  //       if(valueA !== undefined && valueB !== undefined){
  //         if(typeof valueA==="number" && typeof valueB === "number"){
  //           return isAsc? valueA-valueB : valueB - valueA;
  //         }
  //         else{
  //           return isAsc
  //             ?valueA.localeCompare(valueB)
  //             :valueA.localeCompare(valueA);
  //         }
  //       }
  //       else{
  //         return 0;
  //       }
  //     }
  //   })

  //   setTableDataone(sortedDataone);
  // };

  const handleSortone = (property) => {
    const isAsc = sortColumnsone[property] === "desc";
    setSortColumnsone((prevSortColumnsone) => ({
      ...prevSortColumnsone,
      [property]: isAsc ? "asc" : "desc",
    }));

    const sortedDataone = tableDataone.slice().sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      console.log(property, "aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa");

      if (valueA !== undefined && valueB !== undefined) {
        if (typeof valueA === "number" && typeof valueB === "number") {
          return isAsc ? valueA - valueB : valueB - valueA;
        } else if (typeof valueA === "string" && typeof valueB === "string") {
          // Handle string sorting
          return isAsc
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
      }

      return 0; // Return 0 for other cases or when values are undefined
    });

    setTableDataone(sortedDataone);
  };

  const handleChangePageone = (event, newPage) => {
    setPageone(newPage);
  };

  const handleChangeRowsPerPageone = (event) => {
    setRowsPerPageone(parseInt(event.target.value, 10));
    setPageone(0);
  };

  const handleToggleDetails = (index) => {
    setExpandedRow((prev) => (prev === index ? null : index));
  };

  const filteredDataone = tableDataone?.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === "string") {
        const lowerCaseValue = value.toLowerCase();
        return lowerCaseValue.includes(searchTermone.toLowerCase());
      } else if (typeof value === "number") {
        const stringValue = value.toString();
        const lowerCaseValue = stringValue.toLowerCase();
        return lowerCaseValue.includes(searchTermone.toLowerCase());
      } else if (value instanceof Date) {
        const formattedDate = new Date(value).toLocaleDateString("en-GB");
        return formattedDate
          .toLowerCase()
          .includes(searchTermone.toLowerCase());
      }
      return false;
    })
  );

  const calculateSrNoone = (index) => {
    return index + 1 + pageone * rowsPerPageone;
  };

  // const fetchData = async () => {
  //   try {
  //     let data = await getOrderByCollectionData(
  //       "rummyTableMaster",
  //       "AddedDate",
  //       "desc"
  //     );

  //     setTableData(data);
  //   } catch (error) {
  //     console.log(error);
  //     handleSnackbarOpen("Error fetching data");
  //   }
  // };

  /*functions for second table */

  const handleSearchChange = (event) => {
    const inputValue = event.target.value.trim();

    if (inputValue == "") {
      setSearchTerm(inputValue);
    } else {
      setSearchTerm(event.target.value);
    }
  };

  const handleSort = (property) => {
    const isAsc = sortColumns[property] === "desc";
    setSortColumns((prevSortColumns) => ({
      ...prevSortColumns,
      [property]: isAsc ? "asc" : "desc",
    }));

    console.log(tableData);

    const sortedData = tableData.slice().sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (property === "addDate") {
        // Compare dates directly
        return isAsc ? valueA - valueB : valueB - valueA;
      } else {
        // Handle other properties
        if (valueA !== undefined && valueB !== undefined) {
          if (typeof valueA === "number" && typeof valueB === "number") {
            return isAsc ? valueA - valueB : valueB - valueA;
          } else {
            // Handle string sorting
            return isAsc
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }
        } else {
          return 0; // Handle the case when either valueA or valueB is undefined
        }
      }
    });

    setTableData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleToggleDetails = (index) => {
  //   setExpandedRow((prev) => (prev === index ? null : index));
  // };

  const filteredData = tableData?.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === "string") {
        const lowerCaseValue = value.toLowerCase();
        return lowerCaseValue.includes(searchTerm.toLowerCase());
      } else if (typeof value === "number") {
        const stringValue = value.toString();
        const lowerCaseValue = stringValue.toLowerCase();
        return lowerCaseValue.includes(searchTerm.toLowerCase());
      } else if (value instanceof Date) {
        const formattedDate = new Date(value).toLocaleDateString("en-GB");
        return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  // const fetchData = async () => {
  //   try {
  //     let data = await getOrderByCollectionData(
  //       "rummyTableMaster",
  //       "AddedDate",
  //       "desc"
  //     );

  //     setTableData(data);
  //   } catch (error) {
  //     console.log(error);
  //     handleSnackbarOpen("Error fetching data");
  //   }
  // };

  // const navigate = useNavigate();

  // const AddUser = () => {
  //   // Navigate to a specific route
  //   navigate('/adduser');
  // };

  return (
    <>
      <div className={`bold-text-grey`}>Admin Coin Log</div>

      <div className={`${style.two_tables} d-flex gap-4 mt-3`}>
        <div className={` ${style.BacgroundUser}`}>
          <div className={`px-3 py-3 `}>
            <div className="d-flex justify-content-between">
              <div>
                <div className={`bold-text-grey`}>Tip LOG</div>
              </div>
              <div className="d-flex justify-content-end align-center pb-3">
                <div className="pr-2">
                  <label for="inputPassword">Search </label>
                </div>
                <div className="mx-2">
                  <input
                    className="searchInput"
                    variant="outlined"
                    value={searchTermone}
                    onChange={handleSearchChangeone}
                  />
                </div>
              </div>
            </div>

            <TableContainer component={Paper} className="table_background">
              <Table>
                <TableHead>
                  <TableRow>
                    {headCellsone.map((headCell) => (
                      <TableCell
                        className={style.tableHead}
                        key={headCell.id}
                        sortDirection={sortColumnsone[headCell.id]}
                      >
                        <TableSortLabel
                          active={sortColumnsone[headCell.id]}
                          direction={sortColumnsone[headCell.id]}
                          onClick={() => handleSortone(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredDataone &&
                    filteredDataone
                      .slice(
                        pageone * rowsPerPageone,
                        pageone * rowsPerPageone + rowsPerPageone
                      )
                      .map((item, index) => {
                        const srNo = calculateSrNoone(index);
                        // let dd = convertDate(item?.AddedDate);
                        return (
                          <>
                            <TableRow key={item.id}>
                              <TableCell className={`${style.plusbutton}`}>
                                <button
                                  title="Toggle Details"
                                  className={style.toggleDetails}
                                  onClick={() => handleToggleDetails(index)}
                                >
                                  {expandedRow === index ? (
                                    <AddCircleIcon />
                                  ) : (
                                    <AddCircleOutlineIcon />
                                  )}
                                </button>
                                {srNo}{" "}
                              </TableCell>
                              <TableCell> {item?.id} </TableCell>
                              <TableCell> {item?.name} </TableCell>
                              <TableCell> {item.tableid} </TableCell>
                            </TableRow>
                            {expandedRow === index && (
                              <TableRow>
                                <TableCell colSpan={headCellsone.length}>
                                  {/* Additional details content */}
                                  <span className={`${style.tiptext}`}>
                                    Tip
                                  </span>
                                  {/* You can place the content here that you want to show when the details are expanded */}
                                  {item.tableid}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="w-100"
              component="div"
              count={filteredDataone.length}
              page={pageone}
              onPageChange={handleChangePageone}
              rowsPerPage={rowsPerPageone}
              onRowsPerPageChange={handleChangeRowsPerPageone}
            />
          </div>
        </div>

        <div className={`${style.BacgroundUserone}`}>
          <div className={`px-3 py-3 `}>
            <div className="d-flex justify-content-between">
              <div>
                <div className={`bold-text-grey`}>Commission LOG</div>
              </div>
              <div className="d-flex justify-content-end align-center pb-3">
                <div className="pr-2">
                  <label for="inputPassword">Search </label>
                </div>
                <div className="mx-2">
                  <input
                    className="searchInput"
                    variant="outlined"
                    value={searchTerm}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>

            <TableContainer component={Paper} className={"table_background"}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        className={`${style.tableHead} ${
                          orderBy === "id" ? style.sorted : ""
                        }`}
                        key={headCell.id}
                        sortDirection={sortColumns[headCell.id]}
                      >
                        <TableSortLabel
                          active={sortColumns[headCell.id]}
                          direction={sortColumns[headCell.id]}
                          onClick={() => handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData &&
                    filteredData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        const srNo = calculateSrNo(index);
                        return (
                          <TableRow key={item.id}>
                            <TableCell> {srNo} </TableCell>
                            <TableCell> {item?.id} </TableCell>
                            <TableCell> {item?.name} </TableCell>
                            <TableCell> {item?.gamecoin} </TableCell>
                            <TableCell> {item?.commisioncoin} </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
};
