import React, { useEffect, useState } from "react";
import style from "./RummyTableMaster.module.css";
import { addDocument } from "../../firebaseAuth/cloudFirestore/setData";
import { updateDocument } from "../../firebaseAuth/cloudFirestore/updateData";
import { useNavigate, useParams } from "react-router-dom";

import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

const AddRummyTableMaster = () => {
  const { id } = useParams();

  console.log(id, "testID");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    BootValue: "",
    PointValue: "",
  
    AddedDate: new Date(),
  });

  const previousPage = useNavigate();

  const handleChange = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseFloat(inputValue);
  
    if (isNaN(parsedValue) || parsedValue <= 0) {
      // If not a valid number or negative, set the value to 0
      setFormData({
        ...formData,
        PointValue: "",
        BootValue: "",
      });
    } else {
      // If valid number, update the state
      const chaalLimitValue = parsedValue * 80;
      const potLimitValue = parsedValue * 1024;
  
      setFormData({
        ...formData,
        PointValue: parsedValue,
        BootValue: chaalLimitValue,
      });
    }
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(true);
    setSnackbarMessage(message);
  
    // Close the Snackbar after 3000 milliseconds (3 seconds)
    setTimeout(() => {
      setSnackbarOpen(false);
      setSubmitting(false); // Reset submitting state
    }, 3000);
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Disable the submit button
    setSubmitting(true);
  
    handleSnackbarOpen(
      id === "add" ? `Added successfully ` : `Updated successfully `
    );
    if (!formData.BootValue || !formData.PointValue) {
      // Enable the submit button before returning
      setSubmitting(false);
      return;
    }
  
    try {
      let documentId;
  
      if (id === "add") {
        const result = await addDocument("rummyTableMaster", formData);
        console.log(result, "trrr");
        documentId = result.id;
      } else {
      
          await updateDocument("rummyTableMaster", id, formData);
      
      
        
      }
  
      previousPage(`/rummytablemaster`);
  
      setFormData({
        BootValue: "",
        PointValue: "",
        AddedDate: new Date(),
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
  

  const getMastertableData = async () => {
    
    try {
      const response = await getDocumentData("rummyTableMaster", id);

      const { PointValue, BootValue, AddedDate } = response;
      console.log(response, "kkk");
      setFormData({
        BootValue: BootValue || "",
        PointValue: PointValue || "",
        AddedDate: AddedDate || new Date(),
      });
    } catch (error) {
      console.error("Error fetching document for edit:", error);
    }
  };
  useEffect(() => {
    if (id !== "add") {
      getMastertableData();
    }
  }, []);

  return (
    <>
      <div className={style.AdduserBackground}>
        <div className="mainHeading">
          <h4 className="tableHeading">
          {id === "add" ? "Add" : "Edit"} Rummy Table Master
          </h4>
        </div>
        <div className={`pt-4 pb-4  table_background mt-3 ${style.inputField}`}>
          <form action="" onSubmit={handleSubmit}>
            <div className="p-2">
              <div className="d-flex">
                <div className={style.labelName}>
                  <label
                    htmlFor="bootValue"
                    className="col-sm-12 col-form-label"
                  >
                    Point Value*
                  </label>
                </div>
                <div className={style.NumberInput}>
                  {" "}
                  <input
                    required
                    type="number"
                    min="0"
                    step="0.01"
                    className="form-control w-100"
                    id="bootValue"
                    onChange={handleChange}
                    value={formData.PointValue}
                  />
                </div>
              </div>
              <div className="d-flex pt-3">
                <div className={style.labelName}>
                  <label
                    htmlFor="chaalLimit"
                    className="col-sm-12 col-form-label"
                  >
                    Boot Value*
                  </label>
                </div>
                <div className={style.NumberInput}>
                  {" "}
                  <input
                    required
                    type="text"
                    className="form-control w-100  disabled"
                    id="chaalLimit"
                    onChange={handleChange}
                    value={formData.BootValue}
                    disabled
                  />
                </div>
              </div>


              <div className={style.FormButton}>
              <button className="custom-btn" type="submit" disabled={submitting}>
              { id==="add" ? "Submit": "Update"}
</button>

                <button
                  className="cancel_btn ms-2"
                  onClick={() => previousPage("/rummytablemaster")}
                >
                  Cancel
                </button>
              </div>
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <SnackbarContent
                style={{ backgroundColor: "#60bf86" }}
                message={snackbarMessage}
              />
            </Snackbar>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddRummyTableMaster;
