import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";

import { getCollectionData } from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import style from "./style.module.css";

const headCells = [
  { id: "Referral-Coins", label: "Referral Coins" },
  { id: "Referral-Level 1", label: "Referral Level 1" },
  { id: "Referral-Level 2", label: "Referral Level 2" },
  { id: "Referral-Level 3", label: "Referral Level 3" },
  { id: "Contact Us", label: "Contact Us" },
  { id: "Privacy-Policy", label: "Privacy Policy" },
  { id: "Terms-Conditions", label: "Terms & Conditions " },
  { id: "Help-Support", label: "Help & Support" },
  { id: "App-Version", label: "App Version" },
  { id: "Game-For-Private", label: "Game For Private" },
  { id: "Joining-Amount", label: "Joining Amount " },
  { id: "Admin-Commission", label: "Admin Commission" },
  { id: "Whatsapp-No.", label: "Whatsapp No. " },
  { id: "action", label: "Action" },
];

// const dummyData = [
//   {
//     id: 1,
//     Referral_Coins: "50",
//     Referral_Level_1: "30%",
//     Referral_Leve_2: "20%",
//     Referral_Leve_3: "0%",
//     Contact_Us: "Contact Us",
//     Privacy_Policy: "Privacy Policy",
//     Terms_Conditions: "Terms & Conditions",
//     Help_Support: "Help & Support",
//     App_Version: "1.2",
//     Game_For_Private: "1",
//     Joining_Amount: "2",
//     AdminCommission: "15",
//     Whatsapp_No: "9292992929",
//   },
// ];

export const Setting = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  

  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await fetchData();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndStopLoader();
  }, []);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value.trim();

    if (inputValue == "") {
      setSearchTerm(inputValue);
    } else {
      setSearchTerm(event.target.value);
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = tableData?.filter((item) =>
  
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  const fetchData = async () => {
    try {
      const data = await getCollectionData("setting");
      setTableData(data);
      console.log(tableData,"TableData")
    } catch (error) {
      handleSnackbarOpen("Error fetching data");
    }
  };
  console.log(tableData,"TableData")

  const handleEdit = (res) => {
    // console.log(res, "jjj");
    navigate(`/setting/settingview/${res.id}`);
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteDocument("tableMaster", id)
      .then(() => {
        handleSnackbarOpen(`Deleted successfully`);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id :`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Setting</h4>
        </div>

        <div>
          <div
            className="w-100  mb-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <label htmlFor="" className="labelCss">
              Search
            </label>
            <input
              className="searchInput"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className={style.container}>
            <TableContainer component={Paper}>
              <Table className="table_background">
                <TableHead>
                  <TableRow sx={{ overflow: "auto", maxWidth: "400px" }}>
                    {headCells.map((headCell) => (
                      <TableCell
                        className={style.tableHead}
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={orderBy === headCell.id ? order : "asc"}
                          onClick={() => handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell>{item.referralCoins}</TableCell>
                            <TableCell>{item.referralLevel1}</TableCell>

                            <TableCell>{item.referralLevel2}</TableCell>
                            <TableCell>{item.referralLevel3}</TableCell>
                            <TableCell  dangerouslySetInnerHTML={{ __html: item.contactUs }} ></TableCell>

                            <TableCell  dangerouslySetInnerHTML={{ __html: item.privacyPolicy }} ></TableCell>


                            <TableCell dangerouslySetInnerHTML={{ __html: item.termAndCondition }}></TableCell>
                            <TableCell dangerouslySetInnerHTML={{ __html: item.helpSupport }}></TableCell>
                            <TableCell>{item.appVersion}</TableCell>
                            <TableCell>{item.gameForPrivate}</TableCell>
                            <TableCell>{item.joiningAmount}</TableCell>
                            <TableCell>{item.adminComission}</TableCell>
                            <TableCell>{item.whatsapp}</TableCell>
                            <TableCell>
                              <div className={style.iconMain}>
                                <button
                                  title="Edit"
                                  className={style.EditIcon}
                                  onClick={() => handleEdit(item)}
                                >
                                  <EditIcon />
                                </button>
                              </div>
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
              {(!filteredData || filteredData.length === 0) && 
      <div className="text-center p-3 "style={{width:"1613px" ,display: loading ? "none" : "block" , backgroundColor:"#f5f7ff"}}> No data available
      </div>}
            </TableContainer>

            <TablePagination
              component="div"
              count={filteredData.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <SnackbarContent
              style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
              message={snackbarMessage}
            />
          </Snackbar>
        </div>
      </div>
    </>
  );
};
