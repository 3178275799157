import React, { useEffect, useRef, useState } from 'react'
import Style from  "./ChipsManagment.module.css"
import { Box, CircularProgress, Snackbar, SnackbarContent } from '@mui/material'
import { useNavigate, useParams } from 'react-router'
import { updateDocument } from '../../firebaseAuth/cloudFirestore/updateData'
import { getDocumentData } from '../../firebaseAuth/cloudFirestore/getData'
export const EditChips = () => {
    const ChipsData = useRef({
       coin:"",
       price:""
    });

    const { id } = useParams();
    const [data,setData]=useState()
    const [loader , setLoader]=useState()
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
  const navigate=useNavigate() 
    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        setSnackbarOpen(true);
      };
      const UpdateChips = async(e)=>{
        e.preventDefault()
        setLoader(true)
        console.log(ChipsData)
        const dd = await updateDocument("Chips", id, ChipsData.current);

        // console.log(dd)
        // setData(dd)
        // ChipsData.current = dd
        handleSnackbarOpen(`Updated successfully`)
        setTimeout(()=>{
            navigate("/chips")
        },1000)
      }
      const getChipsData = async () => {
        try {
            const response = await getDocumentData("Chips", id);
            //   console.log(response)
            //   return


            console.log(response, "kkk");
            ChipsData.current = response


            //   UserData.current({
            //     boot_name: botname || "",
            //     wallet: wallet || "",
            //     date: date || new Date().toISOString().split("T")[0],
            //   });
            setLoader(false)
        } catch (error) {
            console.error("Error fetching document for edit:", error);
            setLoader(false)
        }
    };

    useEffect(() => {
        getChipsData()
    }, [])

  return (
    <>
    
    {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
    <div className={Style.AdduserBackground}>
        <div>
            <span className={`tableHeading`}>Edit Chips</span>
        </div>
      <form onSubmit={UpdateChips}>
      <div className={`px-3 pt-3 pb-3 mt-3  ${Style.inputField}`}>
            <div className='d-flex  '>
                <div className={Style.labelName}>
                    <label for="inputPassword" className="col-sm-12 col-form-label"> Coin *</label
                    ></div>
                <div className={Style.NumberInput}> 
                <input type="text" className="form-control" id="inputPassword" required
                 onChange={(e)=>{
                   ChipsData.current.coin=e.target.value
                  
                 }}
                 defaultValue=   { ChipsData.current.coin}

                  />
                </div>
            </div>
            <div className='d-flex pt-3'>
            <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label">Price*</label></div>
                <div className={Style.NumberInput}>
                     <input type="number" className="form-control" id="inputPassword" required
                        onChange={(e)=>{
                            ChipsData.current.price=e.target.value
                          }}
                          defaultValue=   { ChipsData.current.price}
                       />
                     </div>
            </div>

            <div className={Style.FormButton}>
                <button className={Style.Submitbutton}  >Update</button>
                <button className={Style.cencelbutton}  onClick={() => navigate("/chips")}>Cancel</button>
            </div>
        </div>
      </form>
      <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <SnackbarContent
                style={{ backgroundColor: "green" }}
                message={snackbarMessage}
              />
            </Snackbar>
            
    </div>
</>
  )
}
