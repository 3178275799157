
import React, { useEffect, useState } from 'react'
import Style from "./User.module.css"
import { useNavigate } from 'react-router';
import { Modal, Table } from 'reactstrap';
import { Box, CircularProgress, Paper, Popover, Snackbar, SnackbarContent, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { getCollectionData, getOrderByCollectionData } from '../../firebaseAuth/cloudFirestore/getData';
import { Delete, Edit, Email, ViewAgenda, Visibility } from '@mui/icons-material';
import { deleteDocument } from '../../firebaseAuth/cloudFirestore/deleteData';
import { updateDocument } from '../../firebaseAuth/cloudFirestore/updateData';
import * as xlsx from 'xlsx';


const data = [
  { id: 1, name: "John", age: 30, salary: 50000 },
  { id: 2, name: "Jane", age: 25, salary: 60000 },
  // Add more data as needed
];

const headCells = [
  { id: "srno", label: "Sr No" },
  { id: "user_name", label: "Name" },
  { id: "bank", label: "Bank Details" },
  { id: "aadhar", label: "Aadhar" },
  { id: "upi", label: "UPI" },
  { id: "mobile", label: "Mobile/Email" },
  { id: "usertype", label: "User Type" },
  { id: "wallet", label: "Wallet" },
  { id: "ontable", label: "On Table" },
  { id: "status", label: "Status" },
  { id: "action", label: "Action" },
];


export const UserListing = (index) => {

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarOpen1, setSnackbarOpen1] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarMessage1, setSnackbarMessage1] = useState("");
  let [deletedDocData, setDeletedDocData] = useState({})
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  console.log(data)
  const [userId, setUserId] = useState()
  // console.log(data)
 
  const downloadExcel = () => {
    const formattedData = data.map((item,index)=> ({
      
     Srno : index+1,
     Name: item.user_name,
     Bank_detail : item.user_bankdetail,
     Aadhar : "",
     UPI : "",
     Mobile_Email : "",
     User_type : item.user_type,
     Wallet : item.wallet,
     On_Table : "No",
     Status : item.isActive?"Active":"Block",
     Date: new Date().toLocaleDateString('en-GB')
       // age: item.age,
     
    }));
    console.log(formattedData)

    const ws = xlsx.utils.json_to_sheet(formattedData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the Excel file
    xlsx.writeFile(wb, 'User.xlsx');
  };
  const handleSearchChange = (event) => {
    if (event.target.value.includes(' ')) {

    } else {

      setSearchTerm(event.target.value);
    }

  };

// const handleSort = (name) => {
//   let property="date"
//   const isAsc = orderBy === property && order === "asc";
//   setOrderBy(property);
//   setOrder(isAsc ? "desc" : "asc");

//   const sortedData = data.slice().sort((a, b) => {
//     const aValue = a.hasOwnProperty(property) ? a[property] : undefined;
//     const bValue = b.hasOwnProperty(property) ? b[property] : undefined;

//     if (typeof aValue === "number" && typeof bValue === "number") {
//       return (isAsc ? 1 : -1) * (aValue - bValue);
//     } else if (aValue instanceof Date && bValue instanceof Date) {
//       return (isAsc ? 1 : -1) * (aValue - bValue);
//     } else {
//       const lowerA = String(aValue).toLowerCase();
//       const lowerB = String(bValue).toLowerCase();
//       return (isAsc ? 1 : -1) * lowerA.localeCompare(lowerB);
//     }
//   });

//   setData(sortedData);
// };
const [sortColumns, setSortColumns] = useState({
  srno:"asc",
  user_name:"asc",
  // bank:"asc",
  // aadhar:"asc",
  // upi:"asc",
  // mobile:"asc",
  // usertype:"asc",
  wallet:"asc",
  // ontable:"asc",
  // status:"asc",
  // action:"asc"
});

const handleSort = (property) => {
  const isAsc = sortColumns[property] === "desc";
  setSortColumns((prevSortColumns) => ({
    ...prevSortColumns,
    [property]: isAsc ? "asc" : "desc",
  }));

  const sortedData = data.slice().sort((a, b) => {
    const valueA = a[property];
    const valueB = b[property];

    if (property === "date") {
      // Compare dates directly
      return isAsc ? valueA - valueB : valueB - valueA;
    } else if (property === "wallet" || typeof valueA === "number") {
      // Compare numeric values for the "wallet" column
      return isAsc ? valueA - valueB : valueB - valueA;
    } else if (typeof valueA === "number" && typeof valueB === "number") {
      // Compare numeric values for other numeric columns
      return isAsc ? valueA - valueB : valueB - valueA;
    } else if (typeof valueA === "string" && typeof valueB === "string") {
      // Compare strings case-insensitively
      const lowerA = valueA.toLowerCase();
      const lowerB = valueB.toLowerCase();
      return isAsc ? lowerA.localeCompare(lowerB) : lowerB.localeCompare(lowerA);
    } else {
      // Handle other properties
      if (valueA !== undefined && valueB !== undefined) {
        return isAsc
          ? String(valueA).localeCompare(String(valueB))
          : String(valueB).localeCompare(String(valueA));
      } else {
        return 0; // Handle the case when either valueA or valueB is undefined
      }
    }
  });

  setData(sortedData);
};

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleSnackbarOpen1 = (message) => {
    setSnackbarMessage1(message);
    setSnackbarOpen1(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  // filteredData

  const filteredData = data?.filter((item) =>
  Object.values(item).some((value) => {
    if (typeof value === "string") {
      const lowerCaseValue = value.toLowerCase();
      return lowerCaseValue.includes(searchTerm.toLowerCase());
    } else if (typeof value === "number") {
      const stringValue = value.toString();
      const lowerCaseValue = stringValue.toLowerCase();
      return lowerCaseValue.includes(searchTerm.toLowerCase());
    } else if (value instanceof Date) {
      const formattedDate = new Date(value).toLocaleDateString("en-GB");
      return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  })
);

const filteredDataWithUserType = filteredData.map((item) => {
  // Check if isBot is false, then set usertype to "Real"
  if (!item.isbot) {
    return { ...item, user_type: "Real" };
  }
  return item;
});


const [userData,setUserData] = useState(filteredData)
const calculateSrNo = (index) => {
  return index + 1 + page * rowsPerPage;
};


function convertDate(date){
  let dd = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
  let aa = dd.toLocaleDateString("en-GB", {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
  return aa;
}

  const navigate = useNavigate();

  const AddUser = () => {
    navigate('/adduser');
  };
  // const getEvent = async () => {
  //   setLoading(true)
  //   let data = await getCollectionData("Users");


  //   setData(data);
  //   setLoading(false)
  //   // handleSnackbarOpen1(`Add User successfully`);
  //   // console.log(data)
  // };

  const fetchData = async () => {
    
    try {
      let data = await getOrderByCollectionData("Users", "date", "desc");

      setData(data);  
     
    } catch (error) {
      console.log(error);
      handleSnackbarOpen("Error fetching data");
    }
    console.log(data,"testttt")
  };
  const HandelaDlt = async (id) => {
    await deleteDocument("Users", id)
      // console.log(id)
      .then(() => {
        // console.log(`Document with id ${id} deleted successfully.`);
        handleSnackbarOpen(`Deleted successfully`);
        fetchData();
      })
      .catch((error) => {
        // console.error(`Error deleting document with id ${id}:`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
  };

  const handleClick = (event,) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const deleteUsers = (item) => {
  //   setDeletedDocData(item)
  //   setDeleteConfirmationModal(true);
  // }
  // const cancelDelete = () => {
  //   setDeleteConfirmationModal(false);
  // }
  function EditUsers(id) {

    console.log(id,)
    navigate(`/edituser/${(id)}`);


  }
  function ViewAllData(id) {
    navigate(`view/${id}`)
  }
  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await fetchData();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndStopLoader();
  }, []);


  const [status, setStatus] = useState(); 

  const handleStatusChange = async (e,index,id) => {
    let arr = [...data]
    arr[index].isActive = JSON.parse(e.target.value)
    setData(arr)
    await updateDocument('Users',id,{isActive:JSON.parse(e.target.value)}) 
    console.log(arr,"check")
  };
  return (
    <>


      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div className={Style.BacgroundUser}>
        <div className='d-flex justify-content-between'>

          <div><h1 className="tableHeading">User</h1></div>
          <div>
            <button className="custom-btn" onClick={AddUser}>Add User</button>
          </div>
        </div>

        <div className='pt-3'>
          <div>
            <button className={Style.ExcelButton}onClick={downloadExcel}>Excel</button>
          </div>
          <div className='d-flex justify-content-end pb-3'>
            <div
              className="w-100  mb-3"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <label htmlFor="" className="labelCss">
                Search
              </label>
              <input
                className="searchInput"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>


        {/* <div className={` ${Style.TableUser}`}> */}

        <TableContainer component={Paper} className="table_background">
        <Table className={`table_background `} >

    

        <TableHead className="table_background">
                <TableRow className="table_background">
                {headCells.map((headCell) => (
                <TableCell className={` table_background ${Style.tableHead}`}
                key={headCell.id}
                sortDirection={sortColumns[headCell.id]}
                >
                  <TableSortLabel
              //  active={sortColumns[headCell.id]}
              active={headCell.id}
               direction={sortColumns[headCell.id]}
               onClick={() => handleSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
                </TableRow>
              </TableHead>
          <TableBody className="table_background">

            
              {filteredDataWithUserType.map((item, index) => {
            
              const srNo = calculateSrNo(index);
              const formattedDate =item.date.toDate().toLocaleDateString('en-GB')
     
              return (
                <>
                  <TableRow className="table_background">

                    <TableCell className="table_background">{srNo}</TableCell>
                    <TableCell className="table_background">{item.user_name}</TableCell>
                    <TableCell className="table_background"></TableCell>
                    <TableCell className="table_background"></TableCell>
                    <TableCell className="table_background"></TableCell>
                    <TableCell className="table_background"> </TableCell>
                    <TableCell className="table_background">{item.user_type}</TableCell>
                    <TableCell className="table_background">{item.bonus.reduce((acc, bonus) => Number(acc) + Number(bonus.bonus), 0) + Number(item.wallet)}</TableCell>
                    <TableCell className="table_background">No</TableCell>
                    <TableCell className="table_background">
                      <select className={`form-select form-select-lg mb-2  ${Style.selectDrop} `} 
                      defaultValue={item.isActive}   onChange={(e)=>handleStatusChange(e,index,item.id)} >
              
                        <option selected className={Style.selectDrop} value={true} >Active</option>
                        {/* <option className={Style.selectDrop} value={1}>Active</option> */}
                        <option className={Style.selectDrop} value={false}>Block</option>

                      </select>
                     


                    </TableCell>
                    <TableCell className="table_background">
                      <div className={``}>
                        {/* <div className="pointer" aria-describedby={id} variant="contained" onClick={handleClick}>
                              <i className="bi bi-three-dots-vertical me-2"></i>
                            </div> */}
                        {/* <Popover
                          id={`popover-${item.id}`}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                            > */}
                        <div className={Style.DropDownWidth}>
                          <div className="p-1 pointer "    title="Edit" onClick={() => EditUsers(item.id)}>
                            <span className={`EditIcon`}><Edit className={Style.Icon} /> </span></div>

                          <div className=" p-1 pointer "  title="Delete" onClick={() => HandelaDlt(item.id)} >
                            <span className={`delete`}><Delete className={Style.Icon1} /></span>
                          </div>
                          <div className=" p-1 pointer"  title="View" onClick={() => ViewAllData(item.id)} >
                            <span className={` view`}><Visibility className={Style.Icon2} /></span>
                          </div>
                        </div>
                        {/* </Popover> */}
                      </div>

                    </TableCell>

                  </TableRow>


                </>
              )
            })}
          </TableBody>
        </Table>
        {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loading ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
</TableContainer>

        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
            message={snackbarMessage}
          />
        </Snackbar>

        {/* </div> */}
      </div>



    </>
  )
}



