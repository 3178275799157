import axios from "axios"
import { baseUrl } from "../auth/api"

export const CreateQrCode = async (data) => {
    try {
        return await axios.post(baseUrl + "qrCodeDetail/addQrDetails", data)
    }
    catch (err) {
        return err
    }
}

export const getAllQrCodeData = async () => {

    try {
        return await axios.get(baseUrl + "qrCodeDetail/getAllQrData")
    }
    catch (err) {
        return err
    }
}
export const getAllQrCodeDetail = async (id) => {
    try {
        return await axios(baseUrl + `qrCodeDetail/printData/${id}`)
    }
    catch (err) {
        return err
    }
}
export const getSingleQrDetail = async (id) => {
    try {
        return await axios(baseUrl + `qrCode/getQrcodeById/${id}`)
    }
    catch (err) {
        return err
    }
}

export const Notification = async (body) => {
    try {
        return await axios.post(baseUrl + `notification/addNotification`,body)
    }
    catch (err) {
        return err
    }
}

export const fatchLoaction = async (lat,long) => {
    try {
        
        return await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBCloz5P5UwYCxbxCgFd7B3bi0AZLAg5CE`)
    }
    catch (err) {
        return err
    }
}