import React, { useRef, useState } from 'react'
import Style from "./Notification.module.css"
import { useNavigate, useParams } from 'react-router';
import { Box, CircularProgress, Snackbar, SnackbarContent } from '@mui/material';
import { addDocument } from '../../firebaseAuth/cloudFirestore/setData';
export const AddNotication = () => {
  const previousPage = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loader, setLoader] = useState(false)
  const [searchTerm, setSearchTerm] = useState("");

  const { id } = useParams();
  const Notify = useRef({

    message: "",
    date: new Date(),


  });
 
  const handleInputChange = (event) => {


    setSearchTerm(Notify.current.message = event.target.value);


  };
  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(!snackbarOpen);

    setSnackbarMessage(message);
    return;
  };
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()


    setLoader(true)

    await addDocument("Notification", Notify.current);

    handleSnackbarOpen(`Added succesfully`)

    setTimeout(() => {
      navigate("/notification")
    }, 2000)
  };

  return (
    <>
      {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
      <div className={Style.AdduserBackground}>
        <div>
          <span className="tableHeading">
            Send Notification</span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={`px-3 pt-3 pb-3 mt-3  ${Style.inputField}`}>
            <div className='d-flex pt-2 '>
              <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label">Message*</label></div>
              <div className={Style.NumberInput}>
                <input type="text" required className="form-control" id="inputPassword"

                  value={searchTerm}
                  onChange={(e)=>{
                    if(e.target.value === ' '){
                      e.target.value = ''
                    }else{
                      handleInputChange(e)
                    }
                  }}
                /></div>
            </div>


            <div className={Style.FormButton}>
              <button className={Style.Submitbutton} type="submit"  >Send</button>
              <button className={Style.cencelbutton} onClick={() => previousPage("/notification")}>Cancel</button>
            </div>
          </div>
        </form>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "green" }}
            message={snackbarMessage}
          />
        </Snackbar>

      </div>
    </>
  )
}