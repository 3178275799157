import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";

import {
  getCollectionData,
  getOrderByCollectionData,
} from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import style from "./table.module.css";

const headCells = [
  { id: "no", label: "Sr. No." },
  { id: "tableid", label: "Table ID" },
  { id: "tabletype", label: "Table Type" },
  { id: "bootvalue", label: "Boot Value" },
  { id: "action", label: "Action" },
];

export const Tables = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [order, setOrder] = useState("asc");
  const [chipsData, setChipsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [sortColumns, setSortColumns] = useState({
    no: "asc",
    coin: "asc",
    price: "asc",
    date: "asc",
    action: "asc",
  });

  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await fetchData();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndStopLoader();
  }, []);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event) => {
    if (event.target.value.includes(" ")) {
    } else {
      setSearchTerm(event.target.value);
    }
  };

  const handleSort = (property) => {
    const isAsc = sortColumns[property] === "desc";
    setSortColumns((prevSortColumns) => ({
      ...prevSortColumns,
      [property]: isAsc ? "asc" : "desc",
    }));

    const sortedData = chipsData.slice().sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];

      if (property === "date") {
        // Compare dates directly
        return isAsc ? valueA - valueB : valueB - valueA;
      } else if (property === "coin" || property === "price") {
        // Parse "Coin" and "Price" values as numbers for comparison
        const numericValueA = parseFloat(valueA);
        const numericValueB = parseFloat(valueB);
        return isAsc
          ? numericValueA - numericValueB
          : numericValueB - numericValueA;
      } else if (typeof valueA === "number" && typeof valueB === "number") {
        // Compare numbers directly
        return isAsc ? valueA - valueB : valueB - valueA;
      } else {
        // Handle string sorting
        const stringA = String(valueA);
        const stringB = String(valueB);
        return isAsc
          ? stringA.localeCompare(stringB)
          : stringB.localeCompare(stringA);
      }
    });

    setChipsData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  const filteredData = chipsData.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === "string") {
        const lowerCaseValue = value.toLowerCase();
        return lowerCaseValue.includes(searchTerm.toLowerCase());
      } else if (typeof value === "number") {
        const stringValue = value.toString();
        const lowerCaseValue = stringValue.toLowerCase();
        return lowerCaseValue.includes(searchTerm.toLowerCase());
      } else if (value instanceof Date) {
        const formattedDate = new Date(value).toLocaleDateString("en-GB");
        return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );
  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  const fetchData = async () => {
    try {
      let data = await getCollectionData("Tables");
      console.log(data);
      setChipsData(data);
    } catch (error) {
      handleSnackbarOpen("Error fetching data");
    }
    console.log(chipsData, "Data");
  };

  // const handleEdit = (id) => {
  //   navigate(`/editchips/${(id)}`)
  // };
  //   function convertDate(date){
  //    let dd = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
  //    let aa = dd.toLocaleDateString("en-GB", {
  //      day: '2-digit',
  //      month: '2-digit',
  //      year: 'numeric'
  //    });
  //    return aa;
  //  }

  const handleDelete = (id) => {
    setLoading(true);
    deleteDocument("Tables", id)
      .then(() => {
        handleSnackbarOpen(`Deleted successfully`);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id :`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Table</h4>
        </div>

        <div>
          {/* <button className={style.ExcelButton}> Excel </button> */}
          <div
            className="w-100  mb-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <label htmlFor="" className="labelCss">
              Search
            </label>
            <input
              className="searchInput"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <TableContainer component={Paper} className="table_background">
            <Table >
              <TableHead >
                <TableRow >
                  {headCells.map((headCell) => (
                    <TableCell
                      className={`${style.tableHeading} table_background`}
                      key={headCell.id}
                      sortDirection={sortColumns[headCell.id]}
                    >
                      <TableSortLabel
                        active={headCell.id}
                        direction={sortColumns[headCell.id]}
                        onClick={() => handleSort(headCell.id)}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    const srNo = calculateSrNo(index);
                    {
                      /* const formattedDate =item.date.toDate().toLocaleDateString('en-GB') */
                    }
                    // console.log(formattedDate,"ttttt")
                    return (
                      <>
                        <TableRow>
                          <TableCell className="">
                            {srNo}
                          </TableCell>
                          <TableCell className="">
                            {item.tableid}
                          </TableCell>

                          <TableCell className="">
                            {item.tabletype}
                          </TableCell>
                          <TableCell className="">
                            {item.bootvalue}
                          </TableCell>
                          <TableCell className="">
                            <div className={`${style.iconMain}`}>
                              {/* <button
                                title="Edit"
                                className={style.EditIcon}
                                onClick={() => handleEdit(item.id)}
                              >
                                <EditIcon />
                              </button> */}
                              <button
                                title="Delete"
                                className={style.delete}
                                onClick={() => handleDelete(item.id)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
            {(!filteredData || filteredData.length === 0) && (
              <div
                className="text-center p-3 "
                style={{
                  display: loading ? "none" : "block",
                  backgroundColor: "#f5f7ff",
                }}
              >
                {" "}
                No data available
              </div>
            )}
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <SnackbarContent
              style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
              message={snackbarMessage}
            />
          </Snackbar>
        </div>
      </div>
    </>
  );
};
