import React, { useEffect, useRef, useState } from 'react'
import Style from "./User.module.css"
import { addDocument } from '../../firebaseAuth/cloudFirestore/setData';
import { Spinner } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { updateArray, updateDocument } from '../../firebaseAuth/cloudFirestore/updateData';
import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";
import { Box, CircularProgress, Snackbar, SnackbarContent } from '@mui/material';



export const Edit = () => {


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const previousPage = useNavigate();
    const [loader, setLoader] = useState(true)
    const [bonus, setBonus] = useState(false)
    const [bonusselected, setBonuselected] = useState(false)
    const { id } = useParams();
    const [amount, setAmount] = useState(0)
    const[bonusadd,setBonusAdd]=useState()

    const UserData = useRef({});
    const navigate = useNavigate()

    const handleSnackbarOpen = (message) => {
        setSnackbarMessage(message);
        console.log(message)
        setSnackbarOpen(true);
    };





    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoader(true)
        setBonuselected('Submitted with amount:', amount, 'Bonus:', bonus);
        // UserData.current.wallet = +(UserData.current.wallet) + +(amount)
       
        // let obj = { ...UserData.current, isBonas: bonus,bonus: +(+(UserData.current.bonus) + +(amount)) }
       
       
        // const dd = await updateDocument("Users", id, obj);
        // console.log(dd)
        let data={bonus:amount,time:new Date(),isBonus:bonus}
        // let data={bonus:amount,time:new Date(),isBonus:bonus}
        console.log(data)
        console.log(id)

        let res=await updateArray("Users",id,"bonus",data)
        console.log(res)
        
        
        // UserData.current = obj
       
        handleSnackbarOpen(
            `Updated successfully`
            );
            setTimeout(() => {
                navigate("/userlisting")
                setLoader(false)
                
        }, 1000);
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    
    //     let data = { bonus: amount, time: new Date().toLocaleDateString(), isBonus: bonus };
    
    //     // Update the bonus array in the document
    //     let res = await updateArray("Users", id, "bonus", data);
    //     console.log(res);
    
    //     // Update the wallet value by adding the bonus amount
    //     let updatedWallet = +(UserData.current.wallet) + +(amount);
    
    //     // Update the wallet value in UserData.current as well
    //     UserData.current.wallet = updatedWallet;
    
    //     // Update the user document with the new wallet value
    //     let walletUpdateResult = await updateDocument("Users", id, { wallet: updatedWallet });
    //     console.log(walletUpdateResult);
    
    //     handleSnackbarOpen(`Updated successfully`);
    
    //     setTimeout(() => {
    //         navigate("/userlisting");
    //     }, 1000);
    // };






    const getMastertableData = async () => {
        try {
            const response = await getDocumentData("Users", id);
           


            console.log(response, "kkk");
            UserData.current = response

            let totalBonus = 0

            response.bonus.forEach(res=>totalBonus += +(res.bonus))
            console.log(response.bonus)
            totalBonus += response.wallet
            console.log(totalBonus)
            setBonusAdd(totalBonus)
           
            setLoader(false)
        } catch (error) {
            console.error("Error fetching document for edit:", error);
            setLoader(false)
        }
    };

    useEffect(() => {
        getMastertableData()
    }, [])

    return (
        <>
          {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
            <div className={Style.AdduserBackground}>
                <div>
                    <span className="tableHeading">
                        Edit User</span>
                </div>
              
                    <form onSubmit={handleSubmit}>
                        <div className={`px-4 pt-4 pb-4 mt-3  ${Style.inputField}`}>
                            <div className='d-flex  '>
                                <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label"> Name*</label></div>
                                <div className={Style.NumberInput}>
                                    <input type="text" required className="form-control" id="inputPassword"
                                        disabled
                                        onChange={(e) => {
                                            UserData.current.bot_name = e.target.value;
                                        }}
                                        defaultValue={UserData.current.user_name}
                                    /></div>
                            </div>
                            <div className='d-flex pt-3'>
                                <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label">Wallet*</label></div>
                                <div className={Style.NumberInput}>
                                    <input type="number" required className="form-control" id="inputPassword"
                                        disabled
                                        onChange={(e) => {
                                            UserData.current.wallet = e.target.value;
                                        }}
                                        value={bonusadd}
                                    />
                                </div>
                            </div>
                            <div className='d-flex pt-3 '>
                                <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label">All Amount To Wallet *</label></div>
                                <div className={Style.NumberInput1}>
                                    <input type="number"  className="form-control" id="inputPassword" placeholder='Enter Amount'
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                </div>
                                <div className='pt-1 ps-4'>
                                    <span>Bonus :-</span>
                                    <div className="form-check form-check-inline ms-3">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions"
                                            onChange={(e) => {
                                                setBonus("Yes")}
                                                
                                            }
                                            // checked={bonus === 'yes'}
                                            id="inlineRadio1" defaultValue="option1" />
                                        <label className="form-check-label Yes" htmlFor="inlineRadio1"

                                        >Yes</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input"
                                         type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2"
                                           onChange={(e) => {
                                            setBonus("No")}
                                            }
                                            checked={bonus === 'No'}
                                          />
                                        <label className="form-check-label Yes" htmlFor="inlineRadio2">No</label>
                                    </div>

                                </div>

                            </div>

                            <div className={Style.FormButton}>

                              
                            <button  className={Style.Submitbutton}  type="submit" 
                           disabled={!bonus}
                            >Update</button>

                                <button className={Style.cencelbutton} onClick={() => previousPage("/userlisting")}>Cancel</button>
                            </div>
                        </div>
                    </form>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={() => setSnackbarOpen(false)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    >
                        <SnackbarContent
                            style={{ backgroundColor: "green" }}
                            message={snackbarMessage}
                        />
                    </Snackbar>


            

            </div>
        </>
    )
}
