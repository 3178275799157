import React, { useEffect, useRef, useState } from "react";
import { Backdrop, Snackbar, SnackbarContent } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";

import Style from "./gift.module.css"
import { addDocument } from "../../firebaseAuth/cloudFirestore/setData";
import { UploadImage } from "../../firebaseAuth/cloudStorage/UploadImage";
import { updateDocument } from "../../firebaseAuth/cloudFirestore/updateData";
import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";

export const AddGift = () => {

    const { id } = useParams();
    const [image1, setImage1] = useState("")
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [imageUrl, setimageUrl] = useState()
    const [loader, setLoader] = useState(false)
    const [storeImg, setStoreImg] = useState()
    const [disabled,setDisabled]= useState(false )
    console.log(storeImg)


    const previousPage = useNavigate();
    // const handleClick = (e) => {
    //     // console.log(e.target.files[0].name,"test")
    //     let id = e.target.id
    //     setAddData({
    //         ...addData,
    //         [e.target.id]: id === "image" ? e.target.files[0] : e.target.value,
    //     });
    //     //   imageConvert(e.target.files[0])
    //     // console.log(e.target.image,"$$$")
    // }
    const handleSnackbarOpen = (message) => {
        setSnackbarOpen(!snackbarOpen);
    
        setSnackbarMessage(message);
        return;
      };
    const handleClick = (e) => {
        if (e.target.id === "image") {
            const selectedFile = e.target.files[0];

            // Update state with the selected file
            setAddData({
                ...addData,
                image: selectedFile,
                previewImage: URL.createObjectURL(selectedFile), // store preview URL
            });
        } else {
            // For other input fields, update the state as before
            setAddData({
                ...addData,
                [e.target.id]: e.target.value,
            });
        }
    };
    const [addData, setAddData] = useState({

        name: "",
        image: "",
        coin: "",
        date: new Date(),
    })
    console.log(addData.image, "testkkk")

    const handleSubmit = async (e) => {
        setDisabled(true)
        handleSnackbarOpen(id === "add" ? `Added successfully ` : `Updated successfully`);
        console.log("submitsubmit")
        let img = {
            name: addData.image.name,
            url: ''
        }
        // if (!addData.name || !addData.image || !addData.coin) {
        //     return;
        //   }
        // setLoader(true)
        e.preventDefault()
        // let giftImage = await UploadImage(addData.image)

        try {
            let giftImage = null;
            console.log(addData);
            if (addData.image.name) {

                // Assuming UploadImage returns the image URL or relevant information
                giftImage = await UploadImage(addData.image);
                img.url = giftImage
                addData.imageName = addData.image.name
                addData.image = giftImage; // Update addData.image with the uploaded image URL
                // addData.immageName 
            }
            if (id === "add") {
                await addDocument("addGift", addData);

            } else {
                await updateDocument("addGift", id, addData);
            }

            previousPage('/gift')


            // alert("submit");
            setAddData({
                name: "",
                image: img,
                coin: "",
                date: new Date()
            });
            console.log(addData.image, "check name")
            setDisabled(false)
        handleSnackbarOpen(id === "add" ? `Added successfully ` : `Updated successfully`);
          


        }
        
        catch (error) {
            console.error("Error submitting the form:", error);
            setDisabled(false)
        }
    };

    const giftManagementData = async () => {
        try {
            const response = await getDocumentData("addGift", id);

            const { name, image, coin, date } = response;
            console.log(response.imageName, "kkk");
            setStoreImg(response.imageName)
            setAddData({
                name: name || "",
                image: image || "",
                coin: coin || "",
                date: date || new Date(),
            });
        } catch (error) {
            console.error("Error fetching document for edit:", error);
        }

    };
    useEffect(() => {
        if (id !== "add") {
            giftManagementData();
        }
    }, []);

    return (
        <>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loader}
            >
                <Box>
                    <Triangle
                        height="80"
                        width="80"
                        color="black"
                        ariaLabel="triangle-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={loader}
                    />
                </Box>
            </Backdrop>
            <div className={Style.AdduserBackground}>
                <div>
                    <h4 className={`tableHeading`}> {id === "add" ? "Add Gift   " : "Edit Gift"}</h4>
                </div>
                <div className={` pt-4 pb-4 mt-3 ${Style.inputField}`}>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="p-2">
                            <div className='d-flex  '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Name*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="text" className="form-control" id="name" onChange={handleClick}
                                        value={addData.name}
                                        required />
                                </div>
                            </div>
                            <div className='d-flex pt-3'>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Image*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="file" className="form-control" id="image" onChange={handleClick}
                                    //    value={addData.image}

                                    // required
                                    />
                                    <label htmlFor="image">
                                        {/* <div> */}
                                        <img src={addData.previewImage ? addData.previewImage : addData.image}
                                            className={Style.imagesize}></img>
                                        {/* {storeImg} */}
                                        {/* </div> */}

                                    </label>


                                </div>
                            </div>
                            <div className='d-flex pt-3 '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Coin*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="number" className="form-control" id="coin" onChange={handleClick}
                                        value={addData.coin}
                                        required />
                                </div>
                            </div>

                            <div className={Style.FormButton}>
                                <button className="custom-btn" type="submit" disabled={disabled}>{id === "add" ? "Submit   " : "Update"}</button>
                                <button className='cancel_btn ms-2' onClick={() => previousPage("/gift")}>Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <SnackbarContent
                    style={{ backgroundColor: "#60bf86" }}
                    message={snackbarMessage}
                />
            </Snackbar>

        </>
    )
}