import React, { useRef, useState } from 'react'
import Style from "./User.module.css"
import { addDocument } from '../../firebaseAuth/cloudFirestore/setData';
import { Spinner } from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Snackbar, SnackbarContent } from '@mui/material';

export const AddUser = () => {
  const previousPage = useNavigate();
  const [snackbarOpen1, setSnackbarOpen1] = useState(false);
  const [snackbarMessage1, setSnackbarMessage1] = useState("");
  const [loader, setLoader] = useState(false)
  const [inputTerm, setInputTerm] = useState("");

  const { id } = useParams();
  const UserData = useRef({

    user_name: "",
    wallet: 0,
    bonus:[],
    // status:"",
    user_type: "",
    date: new Date()
    

  });
  const handleInputChange = (e) => {
    

      setInputTerm(  UserData.current.user_name = e.target.value);
    

  };

  const handleSnackbarOpen1 = (message) => {
    setSnackbarOpen1(true);

    setSnackbarMessage1(message);
    // return;
  };
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault()

    setLoader(true)
    UserData.current.user_type = "Bot";
    
    await addDocument("Users", {...UserData.current,isbot:true ,isActive:true});
    handleSnackbarOpen1(`Added successfully`);
    
    // setLoader(false)
    setTimeout(()=>{
      navigate("/userlisting")
    },2000)
  };

  return (
    <>
      {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
      <div className={Style.AdduserBackground}>
        <div>
          <span className="tableHeading">
            Add User</span>
        </div>

        <form onSubmit={handleSubmit}>
          <div className={`px-3 pt-3 pb-3 mt-3  ${Style.inputField}`}>
            <div className='d-flex  '>
              <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label">Bot Name*</label></div>
              <div className={Style.NumberInput}>
                <input type="text" required className="form-control" id="inputPassword"
    onChange={(e)=>{
      if(e.target.value === ' '){
        e.target.value = ''
      }else{
        handleInputChange(e)
      }
    }}
                  value={inputTerm}
                /></div>
            </div>
            <div className='d-flex pt-3'>
              <div className={Style.labelName}><label for="inputPassword" className="col-sm-12 col-form-label">Wallet*</label></div>
              <div className={Style.NumberInput}>
                <input type="number" required className="form-control" id="inputPassword"
                  onChange={(e) => {
                    const enteredValue = parseFloat(e.target.value);
                    const positiveValue = isNaN(enteredValue) ? '' : Math.max(enteredValue, 0);
                    UserData.current.wallet = positiveValue;
                    e.target.value = positiveValue;
                  }}
                />
              </div>
            </div>

            <div className={Style.FormButton}>
              <button className={Style.Submitbutton} type="submit"  >Submit</button>
              <button className={Style.cencelbutton} onClick={() => previousPage("/userlisting")}>Cancel</button>
            </div>
          </div>
        </form>

        <Snackbar
          open={snackbarOpen1}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen1(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "green", fontWeight: "700" }}
            message={snackbarMessage1}
          />
        </Snackbar>
      </div>
    </>
  )
}
