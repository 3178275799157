import { Box, CircularProgress, Paper, Snackbar, SnackbarContent, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { getCollectionData, getOrderByCollectionData } from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Style from "./Notification.module.css"
import { useNavigate } from "react-router";

const headCells = [
  { id: "no", label: "Sr. No." },
  { id: "message", label: "Message" },
  { id: "date", label: "Added Date " },

];
export const Notification = () => {

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  let [deletedDocData, setDeletedDocData] = useState({})
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [showButtons, setShowButtons] = useState(false);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);
  console.log(data)
  const [userId, setUserId] = useState()
  console.log(data)


 


  const handleSearchChange = (event) => {
    if (event.target.value.includes(' ')) {

    } else {

      setSearchTerm(event.target.value);
    }

  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrderBy(property);
    setOrder(isAsc ? "asc" : "desc");
  
    const sortedData = [...data].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];
  
      if (property === "message" || property === "title") {
        // Use localeCompare for string comparison
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
  
      // For numeric sorting, use the default comparison
      return isAsc ? aValue - bValue : bValue - aValue;
    });
  
    setData(sortedData);
  };
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 8));
    setPage(0);
  };

  const filteredData = data?.filter((item) =>
  Object.values(item).some((value) => {
    if (typeof value === "string") {
      const lowerCaseValue = value.toLowerCase();
      return lowerCaseValue.includes(searchTerm.toLowerCase());
    } else if (typeof value === "number") {
      const stringValue = value.toString();
      const lowerCaseValue = stringValue.toLowerCase();
      return lowerCaseValue.includes(searchTerm.toLowerCase());
    } else if (value instanceof Date) {
      const formattedDate = new Date(value).toLocaleDateString("en-GB");
      return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  })
);

const calculateSrNo = (index) => {
  return index + 1 + page * rowsPerPage;
};

  const navigate = useNavigate();

  const AddNotification = () => {
    navigate('/notification/addnotifaction');
  };
  const getEvent = async () => {
    setLoading(true)
    let data = await getOrderByCollectionData("Notification","date", "desc");


    setData(data);
    setLoading(false)
    // console.log(data)
  };
  // const HandelaDlt = async(id) => {
  //   await deleteDocument("Users", id)
  //   // console.log(id)
  //     .then(() => {
  //       console.log(`Document with id ${id} deleted successfully.`);
  //       handleSnackbarOpen(`Deleted successfully`);
  //       getEvent();
  //     })
  //     .catch((error) => {
  //       console.error(`Error deleting document with id ${id}:`, error);
  //       handleSnackbarOpen(`Error deleting document`);
  //     });
  // };

  const handleClick = (event,) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function convertDate(date){
    let dd = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    let aa = dd.toLocaleDateString("en-GB", {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    return aa;
  }

  // const deleteUsers = (item) => {
  //   setDeletedDocData(item)
  //   setDeleteConfirmationModal(true);
  // }
  // const cancelDelete = () => {
  //   setDeleteConfirmationModal(false);
  // }
  // function EditUsers(id) {

  //   console.log(id,)
  //     navigate(`/edituser/${(id)}`);


  // }
  // function ViewAllData (){
  //   navigate(`/view`)
  // }
  useEffect(() => {
    getEvent();
  }, []);
  return (
    <>


      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div className={Style.BacgroundUser}>
        <div className='d-flex justify-content-between'>

          <div><h1 className="tableHeading">Notification</h1></div>
          <div>
            <button className="custom-btn" onClick={AddNotification}>Add Notification</button>
          </div>
        </div>

        <div className='pt-3'>

          <div className='d-flex justify-content-end pb-3'>
            <div
              className="w-100  mb-3"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <label htmlFor="" className="labelCss">
                Search
              </label>
              <input
                className="searchInput"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
          </div>
        </div>


        {/* <div className={` ${Style.TableUser}`}> */}


        <TableContainer component={Paper} className="table_background">
        <Table component={Paper}className={`table_background`} >
          <TableHead className="table_background">
            <TableRow className="table_background ">
              {headCells.map((headCell) => (
                 <TableCell
        className={`${Style.tableHead} table_background`}
        key={headCell.id}
        sortDirection={orderBy === headCell.id ? order : false}
      >
       
          <TableSortLabel
            active={headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={() => handleSort(headCell.id)}
          >
            {headCell.label}
          </TableSortLabel>
        
      </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className="table_background">

            {filteredData
             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
             .map((item, index) => {
              console.log(item.id)
              const srNo = calculateSrNo(index);
              let timing = convertDate(
                item?.date
              )
              return (
                <>
                  <TableRow className="table_background  ">

                    <TableCell className="table_background ">{srNo}</TableCell>
                    <TableCell className="table_background">{item.message}</TableCell>
                    <TableCell className="table_background">{timing}</TableCell>


                  </TableRow>


                </>
              )
            })}
          </TableBody>
        </Table>
        {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loading ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
</TableContainer>

        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
            message={snackbarMessage}
          />
        </Snackbar>
        {/* </div> */}
      </div>



    </>
  )
}



