import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";
import * as xlsx from 'xlsx';
import { getCollectionData } from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import style from "./style.module.css";

const headCells = [
  { id: "no", label: "Sr. No." },
  { id: "userName", label: "User Name" },
  { id: "planId", label: "Plan Id" },
  { id: "coins", label: "Coins " },
  { id: "price", label: "Price" },
  { id: "addDate", label: "Added Date " },
];

const dummyData = [
  {
    id: 1,
    userName: "Olivia",
    planId: 123221,
    coins: 221,
    price: "₹221",
    date: "01/02/2021",
  },
  {
    id: 2,
    userName: "Emma",
    planId: 123222,
    coins: 21,
    price: "₹231",
    date: "02/02/2023",
  },
  {
    id: 3,
    userName: "William",
    planId: 175224,
    coins: 71,
    price: "₹299",
    date: "01/01/2019",
  },
  {
    id: 4,
    userName: "James",
    planId: 155414,
    coins: 71,
    price: "₹299",
    date: "01/01/2019",
  },
  {
    id: 5,
    userName: "Sophia",
    planId: 176414,
    coins: 100,
    price: "₹499",
    date: "01/07/2019",
  },
  {
    id: 6,
    userName: "Benjamin",
    planId: 165512,
    coins: 50,
    price: "₹399",
    date: "15/05/2022",
  },
  {
    id: 7,
    userName: "Ava",
    planId: 144313,
    coins: 42,
    price: "₹269",
    date: "10/12/2020",
  },
  {
    id: 8,
    userName: "Alexander",
    planId: 198715,
    coins: 120,
    price: "₹599",
    date: "05/08/2022",
  },
  {
    id: 9,
    userName: "Mia",
    planId: 177116,
    coins: 85,
    price: "₹449",
    date: "20/03/2021",
  },
  {
    id: 10,
    userName: "Elijah",
    planId: 155217,
    coins: 65,
    price: "₹349",
    date: "12/09/2020",
  },
];

export const PurChaseHistory = () => {

  const [sortColumns, setSortColumns] = useState({
    // no: "asc",  
    userName:"asc",
    planId:"asc",
    coins:"asc",
    price: "asc",
    addDate: "asc",
  });


  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState(dummyData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await fetchData();
      } finally {
        // Introduce a delay before setting loading to false
        setTimeout(() => {
          setLoading(false);
        }, 1000); // Adjust the delay time as needed (in milliseconds)
      }
    };

    const calculateSrNo = (index) => {
      return index + 1 + page * rowsPerPage;
    };

    fetchDataAndStopLoader();
  }, []);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const handleSort = (property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrderBy(property);
  //   setOrder(isAsc ? "desc" : "asc");
  // };

  const handleSort = (property) => {
    const isAsc = sortColumns[property] === "desc";
    setSortColumns((prevSortColumns) => ({
      ...prevSortColumns,
      [property]: isAsc ? "asc" : "desc",
    }));

    console.log(tableData)
  
    const sortedData = tableData.slice().sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
  
      if (property === "addDate") {
        // Compare dates directly
        return isAsc ? valueA - valueB : valueB - valueA;
      } else {
        // Handle other properties
        if (valueA !== undefined && valueB !== undefined) {
          if (typeof valueA === "number" && typeof valueB === "number") {
            return isAsc ? valueA - valueB : valueB - valueA;
          } else {
            // Handle string sorting
            return isAsc
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }
        } else {
          return 0; // Handle the case when either valueA or valueB is undefined
        }
      }
    });
  
    setTableData(sortedData);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const filteredData = dummyData?.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       typeof value === "string" &&
  //       value.toLowerCase().includes(searchTerm.toLowerCase())
  //   )
  // );

  
  const filteredData = tableData?.filter((item) =>
  Object.entries(item).some(([key, value]) => {
    if (typeof value === "string") {
      return value.toLowerCase().includes(searchTerm.toLowerCase());
    } else if (typeof value === "number") {
      return value.toString().includes(searchTerm.toLowerCase());
    } else if (value instanceof Date) {
      const formattedDate = new Date(value).toLocaleDateString("en-GB");
      return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  })
);

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  }

  const fetchData = async () => {
    try {
      let data = await getCollectionData( tableData);
      setTableData(data);
    } catch (error) {
      // handleSnackbarOpen("Error fetching data");
    }
  };
  const downloadExcel = () => {
    const formattedData = tableData.map(item => ({
     Srno : item.id,
     UserName: item.userName,
     PlanId : item.planId,
     Coins : item.coins,
     Price : item.price,
  
     Date: new Date().toLocaleDateString('en-GB')
       // age: item.age,
     
    }));

    const ws = xlsx.utils.json_to_sheet(formattedData);
    const wb = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Save the Excel file
    xlsx.writeFile(wb, 'PurchaseHistory.xlsx');
  };

  const handleEdit = (res) => {
    navigate(`/mastertable/table/${res.id}`);
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteDocument("tableMaster", id)
      .then(() => {
        handleSnackbarOpen(`Deleted successfully`);
        setLoading(false);
        // fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id :`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Purchase History Management</h4>
        </div>

        <div>
          <button className={style.ExcelButton} onClick={downloadExcel}> Excel </button>
          <div
            className="w-100  mb-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <label htmlFor="" className="labelCss">
              Search
            </label>
            <input
              className="searchInput"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <TableContainer component={Paper}>
            <Table className="table_background">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell
                    className={style.tableHead}
                    key={headCell.id}
                    sortDirection={sortColumns[headCell.id]}
                    >
                      <TableSortLabel
              active={sortColumns[headCell.id]}
              direction={sortColumns[headCell.id]}
              onClick={() => handleSort(headCell.id)}
            >
                        {headCell.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item,index) => {
                    const srNo = calculateSrNo(index);
                    return (
                      <>
                        <TableRow>
                          <TableCell>{srNo}</TableCell>
                          <TableCell>{item.userName}</TableCell>

                          <TableCell>{item.planId}</TableCell>
                          <TableCell>{item.coins}</TableCell>
                          <TableCell>{item.price}</TableCell>
                          <TableCell>{item.date}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
            
            
            {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loading ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <SnackbarContent
              style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
              message={snackbarMessage}
            />
          </Snackbar>
        </div>
      </div>
    </>
  );
};
