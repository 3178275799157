import {  createSlice } from '@reduxjs/toolkit'


export const loaderSlice = createSlice({
    name: 'loader',
    initialState:false,
    reducers: {
        startLoader:()=>{
            return true
        },
        stopLoader:()=>{
            return false
        }
    },
   
})
export const {startLoader,stopLoader}=loaderSlice.actions
