import React, { useState } from "react";
import "./Sidebar.css";
import { Menu } from "@mui/icons-material";
import { Backdrop, Box, useMediaQuery } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Triangle } from "react-loader-spinner";

import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TableRowsIcon from "@mui/icons-material/TableRows";
import PaymentIcon from "@mui/icons-material/Payment";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import CircleNotificationsRoundedIcon from "@mui/icons-material/CircleNotificationsRounded";
import SettingsApplicationsRoundedIcon from "@mui/icons-material/SettingsApplicationsRounded";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";

export const Sidebar = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [loader, setLoader] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [isOpen, setIsOpen] = useState(true);
  let navigate = useNavigate();

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <Box>
          <Triangle
            height="80"
            width="80"
            color="black"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loader}
          />
        </Box>
      </Backdrop>

      <div className="d-flex">
        <div>
          {toggleSidebar && (
            <div
              // initial={{ width: 0 }}
              style={{ width: isOpen ? "240px" : "80px", overflow: "hidden" }}
              // exit={{ width: 0 }}
              // transition={{ duration: 0.1 }}
              className="sidebar_main_con"
              // style={{
              //   ,
              // }}
            >
              <div
              style={{ width: !isOpen ? "80px" : "240px", overflow: "hidden",cursor: "pointer" }}
                className="align-center text-center dimenstion_of_logo"
                onClick={() => {
                  navigate("/");
                }}
                
              >
                {/* <img
                  src="images/teenpatti.png"
                  style={{ width: isOpen ? "45%" : "85%" }}
                  alt=""
                /> */}
              </div>
              <div
                className="sidebar_item_con  px-3"
                style={{
                  overflowX: !isOpen ? "hidden" : "visible",
                  height: isOpen ? "calc(100vh - 132px)" : "calc(100vh - 77px)",
                }}
              >
                <NavLink
                  to={"/"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <DashboardIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100"
                      style={{
                        display: !isOpen ? "none" : "inline-block",
                      }}
                    >
                      Dashboard
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>

                <NavLink
                  to={"/userlisting"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <AccountCircleIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100"
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      User
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>

                <NavLink
                  to={"/table"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <TableRowsIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Table{" "}
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/mastertable"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <TableRestaurantIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Table Master
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/rummytablemaster"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <TableRowsIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Rummy Table Master
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/chips"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <PaymentIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Chips Management
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/gift"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <CardGiftcardIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Gift Management
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>

                <NavLink
                  to={"/Purchase"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <PaymentIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Purchase History
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/Leaderboard"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <LeaderboardIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Leaderboard
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/notification"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <CircleNotificationsRoundedIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Notification
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/welcomebonus"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <TableRowsIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Welcome Bonus
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
                <NavLink
                  to={"/setting"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <SettingsApplicationsRoundedIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Setting
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>

                <NavLink
                  to={"/reedemNow"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <TableRowsIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Redeem Management
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>

                <NavLink
                  to={"/WithdrawalLog"}
                  className={"navLink"}
                  activeClassName="navLinkActive"
                >
                  <div
                    className="sidebaritem"
                    style={{
                      justifyContent: isOpen ? "" : "center",
                      marginBottom: isOpen ? "" : "10px",
                    }}
                  >
                    <span>
                      <TableRowsIcon
                        style={{
                          fontSize: isOpen ? "20px" : "20px",
                        }}
                      />
                    </span>
                    <div
                      className=" mx-2 text-Css w-100 "
                      style={{ display: !isOpen ? "none" : "inline-block" }}
                    >
                      Withdrawal Log
                    </div>
                  </div>
                  <hr className="mb-3 mt-1" />
                </NavLink>
              </div>
            </div>
          )}
        </div>

        <div style={{ flex: 1 }} className="overflow-auto">
          <div className="top_bar d-flex align-items-center justify-content-between ">
            <span style={{ cursor: "pointer" }}>
              <div className={`logout_logo_color`}>
                <Menu
                  className="fs-1 ms-3"
                  onClick={() => setIsOpen(!isOpen)}
                />
              </div>{" "}
            </span>
            <Link
              style={{ textDecoration: "none", marginRight: "18px" }}
              to={"/login"}
              onClick={() => {
                localStorage.removeItem("isLoggedIn");
              }}
            >
              <span
                style={{ color: "black" }}
                onClick={() => {
                  localStorage.setItem("isLoggedIn", "false");
                }}
              >
                <h6 className="d-flex align-items-center pe-3 pt-2 logout_logo_color">
                  <span
                    className={`logout_logo_color`}
                    style={{ visibility: toggleSidebar ? "visible" : "hidden" }}
                  >
                    <LogoutIcon style={{ fontSize: 24 }} />
                  </span>{" "}
                  <span
                    style={{ visibility: toggleSidebar ? "visible" : "hidden" }}
                  >
                    &nbsp;Logout
                  </span>
                </h6>
              </span>
            </Link>
          </div>
          <div className="child_con p-4" style={{ backgroundColor: "#f9f9f9" }}>
            {children}
          </div>
        </div>
      </div>
    </>
  );
};
