import React, { useState, useEffect } from "react";
import {
  getCollectionData,
  getOrderByCollectionData,
} from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Style from "./gift.module.css";
import { CircularProgress, TablePagination } from "@mui/material";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
// import "./table.css"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableSortLabel,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { useNavigate } from "react-router";
// import "../../index.css";

const headCells = [
  { id: "sno", label: "Sr.No" },
  { id: "name", label: "Name" },
  { id: "image", label: "Image" },
  { id: "coin", label: "Coins" },
  { id: "date", label: "Added Date" },
  { id: "action", label: "Action" },
];

export const GiftManagement = () => {
  const [tableData, setTableData] = useState([]);

  const [loader, setLoader] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("coin");
  const [order, setOrder] = useState("asc");
  const [reverse, setReverse] = useState("asc");
  const navigate = useNavigate();

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // const handleSort = (property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrderBy(property);
  //   setOrder(isAsc ? "desc" : "asc");
  // };

  // const filteredData = tableData.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       typeof value === "string" &&
  //       value.toLowerCase().includes(searchTerm.toLowerCase())
  //   )
  // );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleSearchChange = (event) => {
    const inputValue = event.target.value.trim();
    if (inputValue == "") {
      setSearchTerm(inputValue);
    } else {
      setSearchTerm(event.target.value);
    }
  };
  //   const filteredData = tableData?.filter((item) =>
  //   Object.values(item).some(
  //     (value) =>
  //       typeof value === "string" &&
  //       value.toLowerCase().includes(searchTerm.toLowerCase())
  //       )
  // );
  const filteredData = tableData?.filter((item) => {
    const name = item.hasOwnProperty("name") ? item.name : "";
    const coin = item.hasOwnProperty("coin") ? item.coin : "";

    const lowerCaseName = name.toLowerCase();
    const lowerCaseCoin = coin.toString().toLowerCase(); // Convert to string before toLowerCase()

    return (
      lowerCaseName.includes(searchTerm.toLowerCase()) ||
      lowerCaseCoin.includes(searchTerm.toLowerCase())
    );
  });

  // const filteredData = tableData?.filter((item) =>
  //   Object.values(item).some((value) => {
  //     if (typeof value === "string") {
  //       const lowerCaseValue = value.toLowerCase();
  //       return lowerCaseValue.includes(searchTerm.toLowerCase());
  //     } else if (typeof value === "number") {
  //       const stringValue = value.toString();
  //       const lowerCaseValue = stringValue.toLowerCase();
  //       return lowerCaseValue.includes(searchTerm.toLowerCase());
  //     } else if (value instanceof Date) {
  //       const formattedDate = new Date(value).toLocaleDateString("en-GB");
  //       return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
  //     }
  //     return false;
  //   })
  // );

  
  // const handleSort = (property) => {
  //   const isAsc = orderBy === property && order === "desc";
  //   setOrderBy(property);
  //   setOrder(isAsc ? "asc" : "desc");

  //   const sortedData = [...tableData].sort((a, b) => {
  //     const aValue = a[property] || 0; // Assume a default value if undefined
  //     const bValue = b[property] || 0; // Assume a default value if undefined

  //     return isAsc ? aValue - bValue : bValue - aValue;
  //   });

  //   setTableData(sortedData);
  // };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "desc";
    setOrderBy(property);
    setOrder(isAsc ? "asc" : "desc");
  
    const sortedData = [...tableData].sort((a, b) => {
      const aValue = a[property];
      const bValue = b[property];
  
      if (property === "name") {
        return isAsc ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
      }
  
      // For numeric sorting, use the default comparison
      return isAsc ? aValue - bValue : bValue - aValue;
    });
  
    setTableData(sortedData);
  };
  

  
  

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  const fetchData = async () => {
    const data = await getOrderByCollectionData("addGift", "date", "desc");
    setTableData(data);
    setLoader(false);
  };

  const handleEdit = (res) => {
    navigate(`/gift/addgift/${res.id}`);
  };

  const handleDelete = (id) => {
    setLoader(true);
    deleteDocument("addGift", id)
      .then(() => {
        console.log(`Document with id ${id} deleted successfully.`);
        handleSnackbarOpen(`Deleted successfully`);

        fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id ${id}:`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
    console.log(id);
  };
  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();
  }, []);

  console.log(tableData, "$$$$$$");
  console.log(tableData.slice().reverse(), "Check");
  // console.log(tableData[0].date, "Mydate")
  return (
    <>
      {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Gift</h4>
          <button
            className="custom-btn"
            onClick={() => navigate("/gift/addgift/add")}
          >
            {" "}
            Add Gift{" "}
          </button>
        </div>

        <div
          className="w-100  mb-3"
          style={{ display: "flex", justifyContent: "end" }}
        >
          <label htmlFor="" className="labelCss">
            Search
          </label>
          <input
            className="searchInput "
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <TableContainer component={Paper}>
          <Table className="table_background">
            <TableHead>
              <TableRow className="tableHeading">
                {/* {headCells.map((headCell) => (
                  <TableCell className="tableHead"
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id} 
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleSort(headCell.id)}
                    >
                      <b>{headCell.label}</b>
                    </TableSortLabel>
                  </TableCell>
                ))} */}
                {headCells.map((headCell) => (
                  <TableCell key={headCell.id}>
                    <TableSortLabel
                      active={ headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => handleSort(headCell.id)}
                      // hideSortIcon={
                      //   headCell.id === "sno" || headCell.id === "action" || headCell.id === "date"
                      //   ||  headCell.id === "name" ||  headCell.id === "sno"
                      // }
                    >
                      <b>{headCell.label}</b>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData &&
                filteredData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    const srNo = calculateSrNo(index);
                    const formattedDate = new Date(
                      item?.date
                    ).toLocaleDateString("en-GB");
                    return (
                      <>
                        <TableRow className="">
                          <TableCell>{srNo}</TableCell>
                          <TableCell style={{ fontFamily: "sans-serif" }}>
                            {item.name}
                          </TableCell>
                          <TableCell>
                            <img
                              src={item.image}
                              className={Style.imagesize}
                            ></img>
                          </TableCell>
                          <TableCell>{item.coin}</TableCell>
                          <TableCell>
                            {item.date.toDate().toLocaleDateString("en-GB")}
                          </TableCell>
                          {/* <TableCell>{formattedDate}</TableCell> */}
                          <TableCell>
                            {" "}
                            <div className={Style.icondiv}>
                              <button
                               title="Edit"
                                className={Style.EditIcon}
                                onClick={() => handleEdit(item)}
                              >
                                <EditIcon />
                              </button>

                              <button
                               title="Delete"
                                className={Style.delete_icon}
                                onClick={() => handleDelete(item.id)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
            </TableBody>
          </Table>
          {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loader ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
        </TableContainer>
      

        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
            message={snackbarMessage}
          />
        </Snackbar>
      </div>
    </>
  );
};
