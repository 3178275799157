import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { addDocument } from "../../firebaseAuth/cloudFirestore/setData";
import { updateDocument } from "../../firebaseAuth/cloudFirestore/updateData";
import { useNavigate, useParams } from "react-router-dom";

import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
const AddTable = () => {
  const { id } = useParams();

  console.log(id, "testID");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [disabled,setDisabled]= useState(false )
  const [formData, setFormData] = useState({
    bootValue: "",
    chaalLimit: "",
    potLimit: "",

    date: new Date(),
  });

  const previousPage = useNavigate();
  const handleChange = (e) => {
    const inputValue = e.target.value;
    const parsedValue = parseFloat(inputValue);
  
    if (isNaN(parsedValue) || parsedValue <= 0) {
      // If not a valid number or negative, set the value to 0
      setFormData({
        ...formData,
        bootValue: "",
        chaalLimit: "",
        potLimit: "",
      });
    } else {
      // If valid number, update the state with parsedValue as a number
      const chaalLimitValue = parsedValue * 128;
      const potLimitValue = parsedValue * 1024;
  
      setFormData({
        ...formData,
        bootValue: parsedValue, // Use parsedValue instead of inputValue
        chaalLimit: chaalLimitValue,
        potLimit: potLimitValue,
      });
    }
  };
  
  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(!snackbarOpen);

    setSnackbarMessage(message);
    return;
  };
  const handleSubmit = async (e) => {
    setDisabled(true);
    e.preventDefault();
    setLoading(true);

    if (!formData.bootValue || !formData.chaalLimit || !formData.potLimit) {
      setLoading(false);
      setDisabled(false);
      return;
    }

    try {
      let documentId;

      if (id === "add") {
        const result = await addDocument("tableMaster", formData);
        documentId = result.id;
      } else {
        if (id) {
          await updateDocument("tableMaster", id, formData);
        } else {
          console.error("ID is undefined");
          setLoading(false);
          setDisabled(false);
          return;
        }
      }

      handleSnackbarOpen(
        id === "add" ? `Added successfully` : `Updated successfully`
      );

      setTimeout(() => {
        previousPage(`/mastertable`);
        setFormData({
          bootValue: "",
          chaalLimit: "",
          potLimit: "",
          date: new Date().toISOString().split("T")[0],
        });
        setLoading(false);
        setDisabled(false);
      }, 1000);
    } catch (error) {
      console.error("Error submitting the form:", error);
      setLoading(false);
      setDisabled(false);
    }
  };
  
  

  const getMastertableData = async () => {
    try {
      const response = await getDocumentData("tableMaster", id);

      const { bootValue, chaalLimit, potLimit, date } = response;
      console.log(response, "kkk");
      setFormData({
        bootValue: bootValue || "",
        chaalLimit: chaalLimit || "",
        potLimit: potLimit || "",
        date: date || new Date(),
      });
    } catch (error) {
      console.error("Error fetching document for edit:", error);
    }
  };
  useEffect(() => {
    if (id !== "add") {
      getMastertableData();
    }
  }, []);

  return (
    <>
      <div className={style.AdduserBackground}>
        <div className="mainHeading">
          <h4 className="tableHeading">
            {id === "add" ? "Add" : "Edit"} Table Master
          </h4>
        </div>
        <div className={`pt-4 pb-4  table_background mt-3 ${style.inputField}`}>
          <form action="" onSubmit={handleSubmit}>
            <div className="p-2">
              <div className="d-flex">
                <div className={style.labelName}>
                  <label
                    htmlFor="bootValue"
                    className="col-sm-12 col-form-label"
                  >
                    Boot Value *
                  </label>
                </div>
                <div className={style.NumberInput}>
                  {" "}
                  <input
                    required
                    type="number"
                    step={0.01}
                    className="form-control w-100"
                    id="bootValue"
                    onChange={handleChange}
                    value={formData.bootValue}
                  />
                </div>
              </div>
              <div className="d-flex pt-3">
                <div className={style.labelName}>
                  <label
                    htmlFor="chaalLimit"
                    className="col-sm-12 col-form-label"
                  >
                    Chaal Limit *
                  </label>
                </div>
                <div className={style.NumberInput}>
                  {" "}
                  <input
                    required
                    type="text"
                    className="form-control w-100  disabled"
                    id="chaalLimit"
                    onChange={handleChange}
                    value={formData.chaalLimit}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex pt-3">
                <div className={style.labelName}>
                  <label
                    htmlFor="potLimit"
                    className="col-sm-12 col-form-label"
                  >
                    Pot Limit *
                  </label>
                </div>
                <div className={style.NumberInput}>
                  {" "}
                  <input
                    required
                    type="text"
                    className="form-control w-100"
                    id="potLimit"
                    onChange={handleChange}
                    value={formData.potLimit}
                    disabled
                  />
                </div>
              </div>

              <div className={style.FormButton}>
                <button disabled={disabled} className="custom-btn" type="submit">
                { id==="add" ? "Submit": "Update"}
                </button>
                <button
                  className="cancel_btn ms-2"
                  onClick={() => previousPage("/mastertable")}
                >
                  Cancel
                </button>
              </div>
            </div>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={() => setSnackbarOpen(false)}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <SnackbarContent
                style={{ backgroundColor: "#60bf86" }}
                message={snackbarMessage}
              />
            </Snackbar>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddTable;
