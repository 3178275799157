import React from "react";
import { Routes, Route } from "react-router";
import { WrongPath } from "./WrongPath";
import { LoginPage } from "./Components/Login/LoginPage";
import { CheckAuth } from "./auth/CheckAuth";
import { LoginKeeper } from "./auth/LoginKeeper";

import { Tables } from "./Components/Table/Tables";

import { ShowQrCodeDetails } from "./Components/ShowQrCodeDetails/ShowQrCodeDetails";

import { Backdrop } from "@mui/material";
import { Triangle } from "react-loader-spinner";
import { useSelector } from "react-redux";
import { MasterTable } from "./Components/MasterTable/MasterTable";
import { RummyTableMaster } from "./Components/RummyTable/RummyTableMaster";
import { UserListing } from "./Components/Users/UserListing";
import { ChipsManagement } from "./Components/ChipsManagement/ChipsManagement";
import { Dashboard } from "./Components/Dashboard/Dashboard";
import { GiftManagement } from "./Components/GiftManagement/GiftManagement";
import { PurChaseHistory } from "./Components/PurchaseHistroy/PurChaseHistory";
import { AdminCoin } from "./Components/AdminCoin/AdminCoin";
import { LeadBoard } from "./Components/LeadBoard/LeadBoard";
import { WelcomeBonus } from "./Components/WelcomeBonus/WelcomeBonus";
import { Notification } from "./Components/Notifications/Notification";
import { Setting } from "./Components/Setting/Setting";
import { ReedemManagement } from "./Components/ReedemManagement/ReedemManagement";
import { WithdrawalLog } from "./Components/WithdrawalLog/WithdrawalLog";
import { AddUser } from "./Components/Users/AddUser";
import { AddGift } from "./Components/GiftManagement/AddGift";
import AddTable from "./Components/MasterTable/AddTable";
import { UserView } from "./Components/Users/UserView";
import { Edit } from "./Components/Users/Edit";
import AddRedeem from "./Components/ReedemManagement/AddRedeem";
import AddRummyTableMaster from "./Components/RummyTable/AddRummyTableMaster";
import EditWelcomeBonus from "./Components/WelcomeBonus/EditWelcomeBonus";
import { SettingView } from "./Components/Setting/SettingView";
import { AddNotication } from "./Components/Notifications/AddNotication";
import { AddChipsManagement } from "./Components/ChipsManagement/AddChipsManagement";
import { EditChips } from "./Components/ChipsManagement/EditChips";
import { ActiveUsers } from "./Components/ActiveUsers/ActiveUsers";

// import { Category } from "@mui/icons-material";

export const Router = () => {
  let loader = useSelector((e) => e.loader);
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <Triangle
          height="80"
          width="80"
          color="black"
          ariaLabel="triangle-loading"
          visible={loader}
        />
      </Backdrop>
      <Routes>
        <Route element={<LoginKeeper />}>
          <Route path="/login" element={<LoginPage />} />
        </Route>
        <Route path="qrDetails/:id" element={<ShowQrCodeDetails />} />

        <Route element={<CheckAuth />}>
          {/* <Route exact path="qrcode" element={<Qrcode />} /> */}
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/userlisting" element={<UserListing />} />
          <Route exact path="/adduser" element={<AddUser />} />
          <Route exact path="/edituser/:id" element={<Edit />} />
          <Route exact path="userlisting/view/:id" element={<UserView />} />
          <Route exact path="/chips" element={<ChipsManagement />} />
          <Route exact path="/addchips" element={<AddChipsManagement />} />
          <Route exact path="/editchips/:id" element={<EditChips />} />
          <Route exact path="/gift" element={<GiftManagement />} />
          {/* <Route exact path="/addgift" element={<AddGift />} /> */}
          <Route exact path="/gift/addgift/:id" element={<AddGift />} />

          <Route exact path="/Purchase" element={<PurChaseHistory />} />
          <Route exact path="/Leaderboard" element={<LeadBoard />} />
          <Route exact path="/welcomebonus" element={<WelcomeBonus />} />
          <Route exact path="/editBonus/:id" element={<EditWelcomeBonus />} />
          <Route exact path="/notification" element={<Notification />} />
          <Route exact path="/notification/addnotifaction" element={<AddNotication />} />
          <Route exact path="/activeusers" element={<ActiveUsers />} />

          <Route exact path="/setting" element={<Setting />} />
          <Route exact path="/setting/settingview/:id" element={<SettingView />} />
          <Route exact path="/reedemNow" element={<ReedemManagement />} />
          <Route exact path="/reedemNow/addredeem/:id" element={<AddRedeem />} />
          <Route exact path="/WithdrawalLog" element={<WithdrawalLog />} />

          <Route exact path="/table" element={<Tables />} />
          <Route exact path="/admincoin" element={<AdminCoin />} />

          <Route exact path="/mastertable" element={<MasterTable />} />

          <Route exact path="/mastertable/table/:id" element={<AddTable />} />

          <Route exact path="/adduser/table/:id" element={<AddRummyTableMaster />} />

          <Route exact path="/rummytablemaster" element={<RummyTableMaster />} />
          
        </Route>
        <Route path="*" element={<WrongPath />} />
      </Routes>
    </>
  );
};
