import React, { useEffect, useState } from 'react'
import { FormGroup, Table } from 'reactstrap';
import Style from "./User.module.css"
import { Paper, Snackbar, SnackbarContent, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material';
import { getCollectionData, getDocumentData } from '../../firebaseAuth/cloudFirestore/getData';
import { useParams } from 'react-router';

const headCells = [
  { id: "", label: "Sr No" },
  { id: "", label: "Amount" },
  { id: "", label: "Bonus" },
  { id: "", label: "Added date" },

];
const headCell = [
  { id: "", label: "Sr No" },
  { id: "", label: "Game ID" },
  { id: "", label: "Amount" },
  { id: "", label: "Added date" },

];
const headCells1 = [
  { id: "", label: "Sr No" },
  { id: "", label: "Plan ID" },
  { id: "", label: "Coins" },
  { id: "", label: "Price" },
  { id: "", label: "Payment Status" },
  { id: "", label: "Added Date" },

];
const headCells2 = [
  { id: "", label: "Sr No" },
  { id: "", label: "User Name" },
  { id: "", label: "Coins" },
  { id: "", label: "Level" },
  { id: "", label: "Added date" },

];
const headCells3 = [
  { id: "", label: "Sr No" },
  { id: "", label: "User Name" },
  { id: "", label: "Coins" },
  // { id: "", label: "Level" },
  { id: "", label: "Added date" },

];


export const UserView = () => {
  let params = useParams()

  const [data, setData] = useState({})
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [activeIconTab, setActiveIconTab] = useState("1");

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };
  // const handleSnackbarOpen = (message) => {
  //   setSnackbarMessage(message);
  //   setSnackbarOpen(true);
  // };

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    if (event.target.value.includes(' ')) {

    } else {

      setSearchTerm(event.target.value);
    }

  };
  const filteredData = data?.bonus?.filter((item) =>
  Object.values(item).some((value) => {
    if (typeof value === "string") {
      const lowerCaseValue = value.toLowerCase();
      return lowerCaseValue.includes(searchTerm.toLowerCase());
    } else if (typeof value === "number") {
      const stringValue = value.toString();
      const lowerCaseValue = stringValue.toLowerCase();
      return lowerCaseValue.includes(searchTerm.toLowerCase());
    } else if (value instanceof Date) {
      const formattedDate = new Date(value).toLocaleDateString("en-GB");
      return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
    }
    return false;
  })
);
  const getViewData = async () => {

    // let data = await getCollectionData("Users");
    let data = await getDocumentData("Users", params.id)
    console.log(data)
    setData(data)

  }
  function convertDate(date) {
    let dd = new Date(date.seconds * 1000 + date.nanoseconds / 1000000);
  
    let formattedDateTime = dd.toLocaleDateString("en-GB", {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }) + ' ' +
    dd.toLocaleTimeString("en-GB", {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  
    return formattedDateTime;
  }

  useEffect(() => {
    getViewData();
  }, []);
  return (
    <>
      <div>

        <div>
          <span className='tableHeading'>View Logs</span>
        </div>

        <div
          tabs
          className={`${Style.nav_main} d-flex mt-4`}
        >

          <button className={`${Style.navitem}`}>
            <div
              tag="a"
              href="#tab"
              className={`${Style.headerbutton} ${activeIconTab === "1" ? "focused" : ""
                } pr-0`}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("1");
              }}
            >
              <span
                style={{
                  color: activeIconTab === "1" ? "#7da0fa" : "black",
                  // borderBottom:
                  //   activeIconTab === "3" ? "2px solid #E03368" : "none",

                  padding: "5px 10px",
                  // borderRadius: "5px",
                  fontWeight: 600,
                  borderBottom:
                    activeIconTab === "1" ? "2px solid #7da0fa" : "none",
                  // paddingBottom: "5px",
                }}
              >
                Wins{" "}
              </span>
            </div>
          </button>
          <button className={`${Style.navitem}`}>
            <div
              tag="a"
              href="#tab"
              className={`${Style.headerbutton} classnames({ active: activeIconTab === "2" })`}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("2");
              }}
            >
              <span
                style={{
                  color: activeIconTab === "2" ? "#7da0fa" : "black",
                  // borderBottom:
                  //   activeIconTab === "3" ? "2px solid #E03368" : "none",

                  padding: "5px 10px",
                  // borderRadius: "5px",
                  fontWeight: 600,
                  borderBottom:
                    activeIconTab === "2" ? "2px solid #7da0fa" : "none",
                  // paddingBottom: "5px",
                }}
              >
                Purchase
              </span>
            </div>
          </button>
          <button className={`${Style.navitem}`}>
            <div
              tag="a"
              href="#tab"
              className={`${Style.headerbutton} classnames({ active: activeIconTab === "3" })`}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("3");
              }}
            >
              <span
                style={{
                  color: activeIconTab === "3" ? "#7da0fa" : "black",
                  // borderBottom:
                  //   activeIconTab === "3" ? "2px solid #E03368" : "none",

                  padding: "5px 10px",
                  // borderRadius: "5px",
                  fontWeight: 600,
                  borderBottom:
                    activeIconTab === "3" ? "2px solid #7da0fa" : "none",
                  // paddingBottom: "5px",
                }}
              >

                Reffer Earn
              </span>
            </div>
          </button>
          <button className={`${Style.navitem}`}>
            <div
              tag="a"
              href="#tab"
              className={`${Style.headerbutton} classnames({ active:activeIconTab === "4" })`}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("4");
              }}
            >
              <span
                style={{
                  color: activeIconTab === "4" ? "#7da0fa" : "black",
                  // borderBottom:
                  //   activeIconTab === "3" ? "2px solid #E03368" : "none",

                  padding: "5px 10px",
                  // borderRadius: "5px",
                  fontWeight: 600,
                  borderBottom:
                    activeIconTab === "4" ? "2px solid #7da0fa" : "none",
                  // paddingBottom: "5px",
                }}
              >
                Purchase Reffer
              </span>
            </div>
          </button>

          <button className={`${Style.navitem}`}>
            <div
              tag="a"
              href="#tab"
              className={`${Style.headerbutton} classnames({ active: activeIconTab === "5" })`}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("5");
              }}
            >
              <span
                style={{
                  color: activeIconTab === "5" ? "#7da0fa" : "black",
                  // borderBottom:
                  //   activeIconTab === "3" ? "2px solid #E03368" : "none",

                  padding: "5px 10px",
                  // borderRadius: "5px",
                  fontWeight: 600,
                  borderBottom:
                    activeIconTab === "5" ? "2px solid #7da0fa" : "none",
                  // paddingBottom: "5px",
                }}
              >
                Welcome Reffer
                {/* Wallet Log */}
              </span>
            </div>
          </button>
          <button className={`${Style.navitem}`}>
            <div
              tag="a"
              href="#tab"
              className={`${Style.headerbutton} classnames({ active: activeIconTab === "6" })`}
              onClick={(ev) => {
                ev.preventDefault();
                toggleIconTab("6");
              }}
            >
              <span
                style={{
                  color: activeIconTab === "6" ? "#7da0fa" : "black",
                  // borderBottom:
                  //   activeIconTab === "3" ? "2px solid #E03368" : "none",

                  padding: "5px 10px",
                  // borderRadius: "5px",
                  fontWeight: 600,
                  borderBottom:
                    activeIconTab === "6" ? "2px solid #7da0fa" : "none",
                  // paddingBottom: "5px",
                }}
              >

                Wallet Log
              </span>
            </div>
          </button>

        </div>
{/* <hr/> */}
        <FormGroup className="mt-4">
          <div
            className={`d-flex flex-column overflow-auto custom_scrollbar ${Style.userlisting}`}
          >
            {activeIconTab === "1" && (
              <>
                <div className='pt-3'>
                  <div>
                    <button className={Style.ExcelButton}>Excel</button>
                  </div>
                  <div className='d-flex justify-content-end pb-3'>
                    <div
                      className="w-100  mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <label htmlFor="" className="labelCss">
                        Search
                      </label>
                      <input
                        className="searchInput"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>


                <Table component={Paper} className="table_background">
                  <TableHead className="table_background" >
                    <TableRow className="table_background">
                      {headCell.map((headCell) => (
                        <TableCell className={`${Style.Tablerow} table_background`}
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {data.map((res, index) => {
                      let srnNUmber = index + 1
                      return (


                        <>
                          <TableRow key={index} className="table_background" >

                          </TableRow>


                        </>
                      )
                    })} */}

                  </TableBody>
                </Table>


{/* 
                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            )}

            {activeIconTab === "2" && (
              <>
                <div className='pt-3'>
                  <div>
                    <button className={Style.ExcelButton}>Excel</button>
                  </div>
                  <div className='d-flex justify-content-end pb-3'>
                    <div
                      className="w-100  mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <label htmlFor="" className="labelCss">
                        Search
                      </label>
                      <input
                        className="searchInput"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <Table component={Paper} className="table_background">
                  <TableHead className="table_background" >
                    <TableRow className="table_background">
                      {headCells1.map((headCell1) => (
                        <TableCell className={`${Style.Tablerow} table_background`}
                          key={headCell1.id}
                          sortDirection={orderBy === headCell1.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell1.id}
                            direction={orderBy === headCell1.id ? order : "asc"}
                            onClick={() => handleSort(headCell1.id)}
                          >
                            {headCell1.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  


                        
                          <TableRow  className="table_background" >


                          </TableRow>



                  </TableBody>
                </Table>



                {/* <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            )}

            {activeIconTab === "3" && (
              <>

                <div className='pt-3'>
                  <div>
                    <button className={Style.ExcelButton}>Excel</button>
                  </div>
                  <div className='d-flex justify-content-end pb-3'>
                    <div
                      className="w-100  mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <label htmlFor="" className="labelCss">
                        Search
                      </label>
                      <input
                        className="searchInput"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <Table component={Paper} className="table_background">
                  <TableHead className="table_background" >
                    <TableRow className="table_background">
                      {headCells3.map((headCell3) => (
                        <TableCell className={`${Style.Tablerow} table_background`}
                          key={headCell3.id}
                          sortDirection={orderBy === headCell3.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell3.id}
                            direction={orderBy === headCell3.id ? order : "asc"}
                            onClick={() => handleSort(headCell3.id)}
                          >
                            {headCell3.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   


                        
                          <TableRow className="table_background" >


                          </TableRow>

                  </TableBody>
                </Table>



                {/* <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            )}

            {activeIconTab === "4" && (
              <>
                <div className='pt-3'>
                  <div>
                    <button className={Style.ExcelButton}>Excel</button>
                  </div>
                  <div className='d-flex justify-content-end pb-3'>
                    <div
                      className="w-100  mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <label htmlFor="" className="labelCss">
                        Search
                      </label>
                      <input
                        className="searchInput"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <Table component={Paper} className="table_background">
                  <TableHead className="table_background" >
                    <TableRow className="table_background">
                      {headCells2.map((headCell) => (
                        <TableCell className={`${Style.Tablerow} table_background`}
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   
                  <TableRow className="table_background" >


</TableRow>
                  </TableBody>
                </Table>



                {/* <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            )}

            {activeIconTab === "5" && (
              <>
                <div className='pt-3'>
                  <div>
                    <button className={Style.ExcelButton}>Excel</button>
                  </div>
                  <div className='d-flex justify-content-end pb-3'>
                    <div
                      className="w-100  mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <label htmlFor="" className="labelCss">
                        Search
                      </label>
                      <input
                        className="searchInput"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <Table component={Paper} className="table_background">
                  <TableHead className="table_background" >
                    <TableRow className="table_background">
                      {headCells2.map((headCell) => (
                        <TableCell className={`${Style.Tablerow} table_background`}
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                   
                  <TableRow className="table_background" >


</TableRow>

                  </TableBody>
                </Table>



                {/* <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            )}
            {activeIconTab === "6" && (
              <>
                <div className='pt-3'>
                  <div>
                    <button className={Style.ExcelButton}>Excel</button>
                  </div>
                  <div className='d-flex justify-content-end pb-3'>
                    <div
                      className="w-100  mb-3"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <label htmlFor="" className="labelCss">
                        Search
                      </label>
                      <input
                        className="searchInput"
                        variant="outlined"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </div>
                </div>

                <Table component={Paper} className={`table_background ${Style.Tableshadow}`}>
                  <TableHead className="table_background" >
                    <TableRow className="table_background">
                      {headCells.map((headCell) => (
                        <TableCell className={`${Style.Tablerow} table_background`}
                          key={headCell.id}
                          sortDirection={orderBy === headCell.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={() => handleSort(headCell.id)}
                          >
                            {headCell.label}
                          </TableSortLabel>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {data.map((res, index) => {
                        console.log(res.bonus)

                        // console.log(data.bonus)
                          const srNo = calculateSrNo(index);
                          const formattedDate = new Date(
                            res?.time
                          ).toLocaleDateString("en-GB");
                        return (

              
                          <>
                           {res?.bonus?.map((item) =>
                          
                            <TableRow key={index} className="table_background" >
                           
                            

                              <TableCell className="table_background"></TableCell>                       
                              <TableCell className="table_background">{item.bonus}</TableCell>                       
                              <TableCell className="table_background">{item.isBonas}</TableCell>                       
                                               
                              <TableCell className="table_background"></TableCell>                       
                           
                            

  
                            </TableRow>
                          )}


                          </>
                        )
                      })} */}
                   {filteredData && filteredData.map((res, ind) => {
          const timing = convertDate(res?.time);
          return (
            <TableRow key={ind} className="table_background">
              <TableCell className="table_background">{calculateSrNo(ind)}</TableCell>
              <TableCell className="table_background">{res.bonus}</TableCell>
              <TableCell className="table_background">{res.isBonus}</TableCell>
              <TableCell className="table_background">{timing}</TableCell>
            </TableRow>
          );
        })}

                  </TableBody>
                </Table>


                {/* <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={() => setSnackbarOpen(false)}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <SnackbarContent
                    style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
                    message={snackbarMessage}
                  />
                </Snackbar>
              </>
            )}
          </div>



        </FormGroup>
      </div>
    </>
  )
}
