import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TablePagination from "@mui/material/TablePagination";

import {
  getCollectionData,
  getMatchingData,
  getOrderByCollectionData,
} from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import style from "./style.module.css";

const headCells = [
  { id: "no", label: "Sr. No." },
  { id: "bootValue", label: "Boot Value" },
  { id: "chaalLimit", label: "Chaal Limit" },
  { id: "potLimit", label: "Pot Limit " },
  { id: "date", label: "Added Date " },
  { id: "Action", label: "Action " },
];

export const MasterTable = () => {

  const [sortColumns, setSortColumns] = useState({
    // no: "asc",
    bootValue: "asc",
    chaalLimit: "asc",
    date: "asc",
    potLimit: "asc",
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("BootValue");
  const [order, setOrder] = useState("asc");
  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();


  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value.trim();

    if (inputValue == "") {
      setSearchTerm(inputValue);
    } else {
      setSearchTerm(event.target.value);
    }
  };

  // const handleSort = (name) => {
  //   let property = "bootValue";
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrderBy(property);
  //   setOrder(isAsc ? "desc" : "asc");
  
  //   const sortedData = tableData.slice().sort((a, b) => {
  //     const aValue = a.hasOwnProperty(property) ? a[property] : undefined;
  //     const bValue = b.hasOwnProperty(property) ? b[property] : undefined;
  
  //     if (property === "bootValue") {
  //       // Special handling for the "Boot Value" column
  //       const numericValueA = parseFloat(aValue);
  //       const numericValueB = parseFloat(bValue);
  //       return isAsc ? numericValueA - numericValueB : numericValueB - numericValueA;
  //     } else if (typeof aValue === "number" && typeof bValue === "number") {
  //       return isAsc ? aValue - bValue : bValue - aValue;
  //     } else {
  //       const lowerA = String(aValue).toLowerCase();
  //       const lowerB = String(bValue).toLowerCase();
  //       return isAsc ? lowerA.localeCompare(lowerB) : lowerB.localeCompare(lowerA);

  //     }
  //   });
  
  //   setTableData(sortedData);
  // };

  const handleSort = (property) => {
    const isAsc = sortColumns[property] === "desc";
    setSortColumns((prevSortColumns) => ({
      ...prevSortColumns,
      [property]: isAsc ? "asc" : "desc",
    }));
  
    const sortedData = tableData.slice().sort((a, b) => {
      const valueA = a[property];
      const valueB = b[property];
  
      if (property === "date") {
        // Compare dates directly
        return isAsc ? valueA - valueB : valueB - valueA;
      } else {
        // Handle other properties
        if (valueA !== undefined && valueB !== undefined) {
          if (typeof valueA === "number" && typeof valueB === "number") {
            return isAsc ? valueA - valueB : valueB - valueA;
          } else {
            // Handle string sorting
            return isAsc
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }
        } else {
          return 0; // Handle the case when either valueA or valueB is undefined
        }
      }
    });
  
    setTableData(sortedData);
  };
  




  console.log(tableData, "hhhhhh")

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = tableData?.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === "string") {
        const lowerCaseValue = value.toLowerCase();
        return lowerCaseValue.includes(searchTerm.toLowerCase());
      } else if (typeof value === "number") {
        const stringValue = value.toString();
        const lowerCaseValue = stringValue.toLowerCase();
        return lowerCaseValue.includes(searchTerm.toLowerCase());
      } else if (value instanceof Date) {
        const formattedDate = new Date(value).toLocaleDateString("en-GB");
        return formattedDate.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  const fetchData = async () => {
    try {
      let data = await getOrderByCollectionData("tableMaster", "date", "desc");

      setTableData(data);

    } catch (error) {
      console.log(error);
      handleSnackbarOpen("Error fetching data");
    }
    console.log(tableData, "testttt")
  };

  const handleEdit = (res) => {
    navigate(`/mastertable/table/${res.id}`);
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteDocument("tableMaster", id)
      .then(() => {
        handleSnackbarOpen(`Deleted successfully`);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id :`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
  };

  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await fetchData();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndStopLoader();
  }, []);


  console.log(filteredData, 'ttetts')
  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Table Master Management</h4>
          <button
            className="custom-btn"
            onClick={() => navigate("/mastertable/table/add")}
          >
            {" "}
            Add Table Master{" "}
          </button>
        </div>

        <div>
          <div
            className="w-100  mb-3"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <label htmlFor="" className="labelCss">
              Search
            </label>
            <input
              className="searchInput"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <TableContainer component={Paper}>
            <Table className="table_background">
              <TableHead>
                <TableRow>
                {headCells.map((headCell) => (
      <TableCell
        className={style.tableHead}
        key={headCell.id}
        sortDirection={sortColumns[headCell.id]}
      >
          <TableSortLabel
             active={sortColumns[headCell.id]}
             direction={sortColumns[headCell.id]}
             onClick={() => handleSort(headCell.id)}
          >
            {headCell.label}
          </TableSortLabel>
      </TableCell>
    ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredData &&
                  filteredData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      const srNo = calculateSrNo(index);
                      const formattedDate = item.date.toDate().toLocaleDateString('en-GB')
                      console.log(formattedDate, "ttttt")
                      return (
                        <TableRow key={item.id}>
                          <TableCell> {srNo} </TableCell>
                          <TableCell> {item?.bootValue} </TableCell>
                          <TableCell> {item?.chaalLimit} </TableCell>
                          <TableCell> {item?.potLimit} </TableCell>
                          <TableCell> {formattedDate} </TableCell>
                          <TableCell>
                            <div className={style.iconMain}>
                              <button
                                title="Edit"
                                className={style.EditIcon}
                                onClick={() => handleEdit(item)}
                              >
                                <EditIcon />
                              </button>
                              <button
                                title="Delete"
                                className={style.delete}
                                onClick={() => handleDelete(item.id)}
                              >
                                <DeleteIcon />
                              </button>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
              </TableBody>
            </Table>
            {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loading ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredData.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={() => setSnackbarOpen(false)}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <SnackbarContent
              style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
              message={snackbarMessage}
            />
          </Snackbar>
        </div>
      </div>
    </>
  );
};
