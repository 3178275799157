import React, { useEffect, useState } from 'react'
import Style from './redeem.module.css'
import { useNavigate, useParams } from "react-router-dom";
import { addDocument } from "../../firebaseAuth/cloudFirestore/setData";
import { UploadImage } from "../../firebaseAuth/cloudStorage/UploadImage";
import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";
import { updateDocument } from "../../firebaseAuth/cloudFirestore/updateData";
import { Box, CircularProgress, Snackbar, SnackbarContent } from '@mui/material';

export default function AddRedeem() {

    const [loader, setLoader] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [disabled,setDisabled]= useState(false )

    const { id } = useParams(); 
    const previousPage = useNavigate();
    const [addData, setAddData] = useState({

        title: "",
        coin:"",
        amount:"",
        image: "",
        coin: "",
        date: new Date(),
    })
    console.log(addData,"All DATA")

    const handleSnackbarOpen = (message) => {
        setSnackbarOpen(!snackbarOpen);
    
        setSnackbarMessage(message);
        return;
      };
 

    // const handleClick =(e)=>{

    //     if (e.target.id === "image") {
    //         const selectedFile = e.target.files[0];

    //         // Update state with the selected file
    //         setAddData({
    //             ...addData,
    //             image: selectedFile,
    //             previewImage: URL.createObjectURL(selectedFile), // store preview URL
    //         });
    //     } else {
    //         // For other input fields, update the state as before
    //         setAddData({
    //             ...addData,
    //             [e.target.id]: e.target.value,
    //         });
    //     }
   

    // };
    const handleClick = (e) => {
        if (e.target.id === "image") {
          const selectedFile = e.target.files[0];
      
          // Update state with the selected file
          setAddData({
            ...addData,
            image: selectedFile,
            previewImage: URL.createObjectURL(selectedFile), // store preview URL
          });
        } else if (e.target.id === "coin") {
          const inputValue = parseInt(e.target.value, 10);
      
          // Check if the input is a valid number
          if (!isNaN(inputValue)) {
            // Check if the input is negative
            const newValue = inputValue < 0 ? 0 : inputValue;
      
            // Update the state or perform other actions
            setAddData({
              ...addData,
              coin: newValue,
            });
          }
        } else {
          // For other input fields, update the state as before
          setAddData({
            ...addData,
            [e.target.id]: e.target.value,
          });
        }
      };
      
    const handleSubmit = async(e)=>{
        setDisabled(true)
        console.log("submitsubmit")
        handleSnackbarOpen(id === "add" ? `Added successfully ` : `Updated successfully`);
        e.preventDefault()
        setLoader(true)
        let img ={
            name: addData.image.name,
            url: ''

        }
        try {
            let giftImage = null;
            console.log(addData);
            if (addData.image.name) {

                // Assuming UploadImage returns the image URL or relevant information
                giftImage = await UploadImage(addData.image);
                img.url = giftImage
                addData.imageName = addData.image.name
                addData.image = giftImage; // Update addData.image with the uploaded image URL
                // addData.immageName 
            }
            if (id === "add") {
                await addDocument("RedeemManagement", addData);

            } else {
                await updateDocument("RedeemManagement", id, addData);
            }
            previousPage("/reedemNow")
        
        setAddData({
            title: "",
            image: img,
            coin: "",
            date: new Date()
        })
        setDisabled(false)

      
    }
    
    catch (error) {
        console.error("Error submitting the form:", error);
        setDisabled(false)

    }
    };

   
    const RedeemManagement = async () => {
        try {
            const response = await getDocumentData("RedeemManagement", id);

            const { title, coin, image, date,amount } = response;
            console.log(response.imageName, "kkk");
            // setStoreImg(response.imageName)
            setAddData({
                title: title || "",
                image: image || "",
                coin: coin || "",
                amount : amount || "",
                date: date || new Date()
            });
        } catch (error) {
            console.error("Error fetching document for edit:", error);
        }

    };
    useEffect(() => {
        if (id !== "add") {
            RedeemManagement();
        }
    }, []);

  return (
    <>
     {loader ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}
    
            <div className={Style.AdduserBackground}>
                <div>
                    <h4 className={`tableHeading`}>{id === "add" ? "Add Redeem   " : "Edit Redeem"}
                    </h4>
                </div>
                <div className={` pt-4 pb-4 mt-3  ${Style.inputField}`}>
                    <form action="" onSubmit={handleSubmit}>
                        <div className="p-2">
                            <div className='d-flex  '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Title*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="text" className="form-control" id="title" onChange={handleClick}
                                        value={addData.title}
                                        required />
                                </div>
                            </div>
                            
                            <div className='d-flex pt-3 '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Coin*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="number" className="form-control" id="coin" onChange={handleClick}
                                        defaultValue={addData.coin}
                                        required />
                                </div>
                            </div>
                            <div className='d-flex pt-3 '>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Amount*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="number" className="form-control" id="amount" onChange={handleClick}
                                        value={addData.amount}
                                        required />
                                </div>
                            </div>
                            <div className='d-flex pt-3'>
                                <div className={Style.labelName}>
                                    <label for="inputPassword" className="col-sm-12 col-form-label">Image*</label>
                                </div>
                                <div className={Style.NumberInput}>
                                    <input type="file" className="form-control" id="image" onChange={handleClick}
                                    //    value={addData.image}

                                    // required
                                    />
                                    <label htmlFor="image">
                                        {/* <div> */}
                                        <img src={addData.previewImage ? addData.previewImage :addData.image}
                                            className={Style.imagesize}></img>
                                        {/* {storeImg} */}
                                        {/* </div> */}

                                    </label>


                                </div>
                            </div>

                            <div className={Style.FormButton}>
                                <button className="custom-btn" type="submit"  disabled={disabled}>{ id==="add" ? "Submit": "Update"}</button>
                                <button className='cancel_btn ms-2' onClick={() => previousPage("/reedemNow")}>Cancel</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
                <SnackbarContent
                    style={{ backgroundColor: "#60bf86" }}
                    message={snackbarMessage}
                />
            </Snackbar>
            </>
  )
}
