import { configureStore } from "@reduxjs/toolkit";

import { loaderSlice } from "./Slices/LoaderSlice";
import { AllQrCodeSlice } from "./Slices/AllQrCodes";

export const store = configureStore({
    reducer: {
        allQrCode: AllQrCodeSlice.reducer,
        loader: loaderSlice.reducer
    },
});