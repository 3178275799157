import { useState } from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { emailPasswordLogin } from "../../firebaseAuth/firebaseAuth/userLogin";
import { getAuth } from "firebase/auth";
// import firebaseApp from "firebase"; // Import the firebase configuration
import {
  Button,
  TextField,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Typography,
  Backdrop,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Triangle } from "react-loader-spinner";

import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";

export const LoginPage = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(min-width:700px)");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [match, setMatch] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [loader, setLoader] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };


  async function handleSubmit(e) {
    e.preventDefault();

    try {
      // const auth = getAuth(firebaseApp);
      let result = await emailPasswordLogin(user, pass);
      console.log(result);
      if (result) {
        localStorage.setItem("isLoggedIn", "true");
        // navigate("/");
        setLoader(false);
          navigate("/");
        setMatch(false);
      }else{
        handleSnackbarOpen(`Invalid Credential`);
      }
    } catch (error) {
      console.error("Error signing in:", error.message);
      setMatch(true); // Show error message for invalid credentials
    } finally {
      setLoader(false);
    }
  }



  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <Box>
          <Triangle
            height="80"
            width="80"
            color="black"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loader}
          />
        </Box>
      </Backdrop>
      <section className={style.main_sec}>
        <div className={style.center}>
          <div className={style.inner}>
            {/* {!matches ? (
              <img
                className="w-100 py-2"
                src="/images/teenpatti.png"
                alt="Logo"
              />
            ) : (
              ""
            )} */}

            <div className={style.upper}>
              <div className={style.upperheading}>Grand Premier League</div>
              <div className={style.upperimage}>
                <img
                  className="w-25 h-100 py-2"
                  src="/images/teenpatti.png"
                  alt="Logo"
                />
              </div>
            </div>

            <div className={style.lower}>
              {!match ? (
                <br />
              ) : (
                <h5 style={{ color: "red" }}>
                  Please Enter valid Id or Password
                </h5>
              )}

              <form onSubmit={handleSubmit}>
                <TextField
                  placeholder="Enter Your Username"
                  required
                  type="email"
                  fullWidth
                    value={user}
                  onChange={(e) => setUser(e.target.value)}
                  InputProps={{
                    sx: {
                      height: "40px", // Adjust the height as needed
                      '& input': {
                        height: '30%', /* Ensure input fills the TextField */
                      },
                    },
                  }}
                />
                <Typography sx={{ fontSize: "14px", mb: 2, color: "black" }}>
                  test@gmail.com
                </Typography>

                <FormControl sx={{ mb: 4 }} fullWidth>
                  <OutlinedInput
                    placeholder="Enter Your Password"
                    type={showPass ? "text" : "password"}
                    value={pass}
                    required
                    onChange={(e) => setPass(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPass(!showPass)}>
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                    sx={{
                      height: "40px", // Adjust the height as needed
                    }}
                  />
                  <small style={{ color: "black" }}>test@1234</small>
                </FormControl>

                <Button
                  className={`${style.loginbutton}`}
                  variant="contained"
                  sx={{ background: "#000", color: "white" }}
                  type="submit"
                  fullWidth
                >
                  LOGIN
                </Button>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Snackbar
          open={snackbarOpen}
          autoHideDuration={1500}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <SnackbarContent
            style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
            message={snackbarMessage}
          />
        </Snackbar>
    </>
  );
};
