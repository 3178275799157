import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import style from "./style.module.css";
import { useParams } from "react-router-dom";
import { getDocumentData } from "../../firebaseAuth/cloudFirestore/getData";
import { updateDocument } from "../../firebaseAuth/cloudFirestore/updateData";
// import { EditorState } from "draft-js";
import JoditEditor from "jodit-react";

const headCells = [
  { id: "Referral-Coins", label: "Referral Coins" },
  { id: "Referral-Level 1", label: "Referral Level 1" },
  { id: "Referral-Level 2", label: "Referral Level 2" },
  { id: "Referral-Level 3", label: "Referral Level 3" },
  { id: "Contact Us", label: "Contact Us" },
  { id: "Privacy-Policy", label: "Privacy Policy" },
  { id: "Terms-Conditions", label: "Terms & Conditions " },
  { id: "Help-Support", label: "Help & Support" },
  { id: "App-Version", label: "App Version" },
  { id: "Game-For-Private", label: "Game For Private" },
  { id: "Joining-Amount", label: "Joining Amount " },
  { id: "Admin-Commission", label: "Admin Commission" },
  { id: "Whatsapp-No.", label: "Whatsapp No. " },
  { id: "action", label: "Action" },
];

// const dummyData = [
//   {
//     id: 1,
//     Referral_Coins: "50",
//     Referral_Level_1: "30%",
//     Referral_Leve_2: "20%",
//     Referral_Leve_3: "0%",
//     Contact_Us: "Contact Us",
//     Privacy_Policy: "Privacy Policy",
//     Terms_Conditions: "Terms & Conditions",
//     Help_Support: "Help & Support",
//     App_Version: "1.2",
//     Game_For_Private: "1",
//     Joining_Amount: "2",
//     AdminCommission: "15",
//     Whatsapp_No: "9292992929",
//   },
// ];

export const SettingView = () => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const editor1 = useRef(null);
  const editor2 = useRef(null);

  const editor3 = useRef(null);

  const editor4 = useRef(null);

  const [content, setContent] = useState("");

  const previousPage = useNavigate();

  // const [editorState1, setEditorState1] = useState(EditorState.createEmpty());
  // const [editorState4, setEditorState4] = useState(EditorState.createEmpty());
  // const [editorState2, setEditorState2] = useState(EditorState.createEmpty());
  // const [editorState3, setEditorState3] = useState(EditorState.createEmpty());
  const [addData, setAddData] = useState({
    referralCoins: "",
    referralLevel1: "",
    referralLevel2: "",
    referralLevel3: "",
    contactUs: "",
    termAndCondition: "",
    helpSupport: "",
    appVersion: "",
    gameForPrivate: "",
    joiningAmount: "",
    adminComission: "",
    whatsapp: "",
    razorAPIKey: "",
    razorSecertKey: "",
    shareText: "",
    symbol: "",
    upiField: "",
    aadharCardField: "",
    bankDetailField: "",
    paymentGateway: "",
    bonus: "",
    privacyPolicy: "",
  });
  const setting = async () => {
    try {
      const response = await getDocumentData("setting", id);
      console.log(response, "kkk");
      const {
        referralCoins,
        referralLevel1,
        referralLevel2,
        referralLevel3,
        contactUs,
        termAndCondition,
        helpSupport,
        appVersion,
        gameForPrivate,
        joiningAmount,
        adminComission,
        whatsapp,
        razorAPIKey,
        razorSecertKey,
        shareText,
        symbol,
        upiField,
        aadharCardField,
        bankDetailField,
        paymentGateway,
        bonus,
        privacyPolicy,
      } = response;

      setAddData({
        referralCoins: referralCoins || "",
        referralLevel1: referralLevel1 || "",
        referralLevel2: referralLevel2 || "",
        referralLevel3: referralLevel3 || "",
        contactUs: contactUs || "",
        termAndCondition: termAndCondition || "",
        helpSupport: helpSupport || "",
        appVersion: appVersion || "",
        gameForPrivate: gameForPrivate || "",
        joiningAmount: joiningAmount || "",
        adminComission: adminComission || "",
        whatsapp: whatsapp || "",
        razorAPIKey: razorAPIKey || "",
        razorSecertKey: razorSecertKey || "",
        shareText: shareText || "",
        symbol: symbol || "",
        upiField: upiField || "",
        aadharCardField: aadharCardField || "",
        bankDetailField: bankDetailField || "",
        paymentGateway: paymentGateway || "",
        bonus: bonus || "",
        privacyPolicy: privacyPolicy || "",
      });
      console.log(addData, "rrrrrrrrrrrr");
    } catch (error) {
      console.error("Error fetching document for edit:", error);
    }
  };
  const { id } = useParams();

  // const handleChange = (editorNum, newEditorState) => {
  //   console.log(newEditorState);
  //   switch (editorNum) {
  //     case 1:
  //       setEditorState1(newEditorState);
  //       break;
  //     case 2:
  //       setEditorState2(newEditorState);
  //       break;
  //     case 3:
  //       setEditorState3(newEditorState);
  //       break;
  //     default:
  //       break;
  //   }
  // };
  const handleClick = (e) => {
    setAddData({
      ...addData,
      [e.target.id]: e.target.value,
    });
  };
  const handleChange = (editorId, newContent) => {
    setAddData({
      ...addData,
      [editorId]: newContent,
    });
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarOpen(!snackbarOpen);

    setSnackbarMessage(message);
    return;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    handleSnackbarOpen(`Updated successfully`);

    try {
      await updateDocument("setting", id, addData);

      previousPage("/setting");

      setAddData({
        referralCoins: "",
        referralLevel1: "",
        referralLevel2: "",
        referralLevel3: "",
        contactUs: "",
        termAndCondition: "",
        helpSupport: "",
        appVersion: "",
        gameForPrivate: "",
        joiningAmount: "",
        adminComission: "",
        whatsapp: "",
        razorAPIKey: "",
        razorSecertKey: "",
        shareText: "",
        symbol: "",
        upiField: "",
        aadharCardField: "",
        bankDetailField: "",
        paymentGateway: "",
        bonus: "",
        privacyPolicy: "",
      });
    } catch (error) {
      console.error("Error submitting the form:", error);
    }
  };
  useEffect(() => {
    setting();
  }, []);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Edit Setting</h4>
        </div>

        <div>
          <div className={style.AdduserBackground}>
            <div className="mainHeading">
              <h4 className="tableHeading"></h4>
            </div>
            <div
              className={`pt-4 pb-4  table_background mt-3 ${style.inputField}`}
            >
              <form action="" onSubmit={handleSubmit}>
                <div className="p-2">
                  <div className="d-flex">
                    <div className={style.labelName}>
                      <label
                        htmlFor="bootValue"
                        className="col-sm-12 col-form-label"
                      >
                        Referral Coins *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="number"
                        className="form-control w-100"
                        id="referralCoins"
                        onChange={handleClick}
                        value={addData.referralCoins}
                      />
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="chaalLimit"
                        className="col-sm-12 w-100 col-form-label"
                      >
                        Referral Level 1 *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100  disabled"
                        id="referralLevel1"
                        onChange={handleClick}
                        value={addData.referralLevel1}
                      />
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Referral Level 2 *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="referralLevel2"
                        onChange={handleClick}
                        value={addData.referralLevel2}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Referral Level 3 *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="referralLevel3"
                        onChange={handleClick}
                        value={addData.referralLevel3}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Contact Us *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      <JoditEditor
                        // id="contatUs"
                        ref={editor1}
                        value={addData.contactUs}
                        config={""}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) =>
                          handleChange("contactUs", newContent)
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Terms & Conditions*
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      <JoditEditor
                        // id="contatUs"
                        ref={editor2}
                        value={addData.termAndCondition}
                        config={""}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) =>
                          handleChange("termAndCondition", newContent)
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Privacy Policy *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      <JoditEditor
                        // id="contatUs"
                        ref={editor3}
                        value={addData.privacyPolicy}
                        config={""}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) =>
                          handleChange("privacyPolicy", newContent)
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Help & Support *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      <JoditEditor
                        // id="contatUs"
                        ref={editor4}
                        value={addData.helpSupport}
                        config={""}
                        tabIndex={1} // tabIndex of textarea
                        onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        onChange={(newContent) =>
                          handleChange("helpSupport", newContent)
                        }
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Game For Private *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="gameForPrivate"
                        onChange={handleClick}
                        value={addData.gameForPrivate}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Joining Amount *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="joiningAmount"
                        onChange={handleClick}
                        value={addData.joiningAmount}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Admin Comission *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="adminComission"
                        onChange={handleClick}
                        value={addData.adminComission}
                      />
                    </div>
                  </div>
                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Whatsapp *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        pattern="[0-9]*" // Allow only numeric characters
                        maxLength="10"
                        type="text"
                        className="form-control w-100"
                        id="whatsapp"
                        onChange={handleClick}
                        value={addData.whatsapp}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        App Version *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="appVersion"
                        onChange={handleClick}
                        value={addData.appVersion}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Bonus *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <select
                        // required
                        type="text"
                        className="form-control w-100"
                        id="bonus"
                        onChange={handleClick}
                        value={addData.bonus}
                      >
                        <option value="yes"> Yes </option>
                        <option value="no"> No </option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Payment Gateway *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <select
                        required
                        type="text"
                        className="form-control w-100"
                        id="paymentGateway"
                        onChange={handleClick}
                        value={addData.paymentGateway}
                      >
                        <option value="razorpay"> Razorpay</option>
                        <option value="whatsapp">Whatsapp </option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Symbol *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <select
                        required
                        type="text"
                        className="form-control w-100"
                        id="symbol"
                        onChange={handleClick}
                        value={addData.symbol}
                      >
                        <option value="rupee">Rupee</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Razor API Key *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="razorAPIKey"
                        onChange={handleClick}
                        value={addData.razorAPIKey}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Share Text *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="shareText"
                        onChange={handleClick}
                        value={addData.shareText}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Bank Detail Field *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="bankDetailField"
                        onChange={handleClick}
                        value={addData.bankDetailField}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        Aadhar Card Field *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="aadharCardField"
                        onChange={handleClick}
                        value={addData.aadharCardField}
                      />
                    </div>
                  </div>

                  <div className="d-flex pt-3">
                    <div className={style.labelName}>
                      <label
                        htmlFor="potLimit"
                        className="col-sm-12 col-form-label"
                      >
                        UPI Field *
                      </label>
                    </div>
                    <div className={style.NumberInput}>
                      {" "}
                      <input
                        required
                        type="text"
                        className="form-control w-100"
                        id="upiField"
                        onChange={handleClick}
                        value={addData.upiField}
                      />
                    </div>
                  </div>

                  <div className={style.FormButton}>
                    <button className="custom-btn" type="submit">
                      Update
                    </button>
                    <button
                      className="cancel_btn ms-2"
                      onClick={() => previousPage("/setting")}
                    >
                      Cancel
                    </button>
                  </div>
                </div>

                <Snackbar
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={() => setSnackbarOpen(false)}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <SnackbarContent
                    style={{ backgroundColor: "#60bf86" }}
                    message={snackbarMessage}
                  />
                </Snackbar>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <SnackbarContent
          style={{ backgroundColor: "#60bf86" }}
          message={snackbarMessage}
        />
      </Snackbar>
    </>
  );
};
