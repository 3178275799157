import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@mui/material";

import TablePagination from "@mui/material/TablePagination";

import { getCollectionData } from "../../firebaseAuth/cloudFirestore/getData";
import { deleteDocument } from "../../firebaseAuth/cloudFirestore/deleteData";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CircularProgress from "@mui/material/CircularProgress";

import style from "./style.module.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const headCells = [
  { id: "1", label: "Sr. No" },
  { id: "2", label: "User Name" },
  { id: "3", label: "Bank Details" },
  { id: "4", label: "Aadhar" },
  { id: "5", label: "UPI" },
  { id: "6", label: "Mobile" },
  { id: "7", label: "Coin" },
  { id: "8", label: "Status" },
];

const dummyData = [
  {
    id: 1,
    user_name: "Jone",
    bank_Details: "8938440434048",
    adhar_no: "783075181085",
    upi_Id: "UPI ID",
    Contact_Us: "9292992929",
    coin: "300",
    status: "Pending",
  },
];

const headCells2 = [
  { id: "1", label: "Sr. No" },
  { id: "2", label: "User Name" },
  ,
  { id: "6", label: "Mobile" },
  { id: "7", label: "Coin" },
  { id: "8", label: "Status" },
];

const dummyData2 = [
  {
    id: 1,
    user_name: "Jone",

    Contact_Us: "9292992929",
    coin: "300",
    status: "Approved",
  },
];

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const WithdrawalLog = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await fetchData();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndStopLoader();
  }, []);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value.trim();

    if (inputValue == "") {
      setSearchTerm(inputValue);
    } else {
      setSearchTerm(event.target.value);
    }
  };

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = dummyData?.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const calculateSrNo = (index) => {
    return index + 1 + page * rowsPerPage;
  };

  const fetchData = async () => {
    try {
      const data = await getCollectionData("tableMaster");
      console.log(data);
    } catch (error) {
      handleSnackbarOpen("Error fetching data");
    }
  };

  const handleEdit = (res) => {
    // console.log(res, "jjj");
    navigate(`/setting/settingview`);
  };

  const handleDelete = (id) => {
    setLoading(true);
    deleteDocument("tableMaster", id)
      .then(() => {
        handleSnackbarOpen(`Deleted successfully`);
        setLoading(false);
        fetchData();
      })
      .catch((error) => {
        console.error(`Error deleting document with id :`, error);
        handleSnackbarOpen(`Error deleting document`);
      });
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className="mainHeading">
          <h4 className="tableHeading">Withdrawal Log</h4>
        </div>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#7da0fa",
                },
              }}
            >
              <Tab label="Pending" style={{ fontWeight: "bold" }} {...a11yProps(0)} />
              <Tab label="Approved" style={{ fontWeight: "bold" }} {...a11yProps(1)} />
              <Tab label="Rejected" style={{ fontWeight: "bold" }} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <div>
              <div
                className="w-100  mb-3"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <label htmlFor="" className="labelCss">
                  Search
                </label>
                <input
                  className="searchInput"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            
                <TableContainer component={Paper}>
                  <Table className="table_background">
                    <TableHead>
                      <TableRow sx={{ overflow: "auto", maxWidth: "400px" }}>
                        {headCells.map((headCell) => (
                          <TableCell
                            className={style.tableHead}
                            key={headCell.id}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={() => handleSort(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.user_name}</TableCell>

                                <TableCell>{item.bank_Details}</TableCell>
                                <TableCell>{item.adhar_no}</TableCell>
                                <TableCell>{item.upi_Id}</TableCell>
                                <TableCell>{item.Contact_Us}</TableCell>

                                <TableCell>{item.coin}</TableCell>
                                <TableCell>{item.status}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </TableBody> 
                  </Table>
                  {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loading ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
                </TableContainer>

                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <SnackbarContent
                  style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
                  message={snackbarMessage}
                />
              </Snackbar>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div>
              <div
                className="w-100  mb-3"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <label htmlFor="" className="labelCss">
                  Search
                </label>
                <input
                  className="searchInput"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className={style.container}>
                <TableContainer component={Paper}>
                  <Table className="table_background">
                    <TableHead>
                      <TableRow sx={{ overflow: "auto", maxWidth: "400px" }}>
                        {headCells2.map((headCell) => (
                          <TableCell
                            className={style.tableHead}
                            key={headCell.id}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={() => handleSort(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dummyData2
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.user_name}</TableCell>

                                <TableCell>{item.Contact_Us}</TableCell>

                                <TableCell>{item.coin}</TableCell>
                                <TableCell>
                                  <select
                                    className="form-control w-100"
                                    name=""
                                    id=""
                                  >
                                    <option>{item.status}</option>
                                  </select>{" "}
                                </TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <SnackbarContent
                  style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
                  message={snackbarMessage}
                />
              </Snackbar>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <div>
              <div
                className="w-100  mb-3"
                style={{ display: "flex", justifyContent: "end" }}
              >
                <label htmlFor="" className="labelCss">
                  Search
                </label>
                <input
                  className="searchInput"
                  variant="outlined"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className={style.container}>
                <TableContainer component={Paper}>
                  <Table className="table_background">
                    <TableHead>
                      <TableRow sx={{ overflow: "auto", maxWidth: "400px" }}>
                        {headCells2.map((headCell) => (
                          <TableCell
                            className={style.tableHead}
                            key={headCell.id}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === headCell.id}
                              direction={
                                orderBy === headCell.id ? order : "asc"
                              }
                              onClick={() => handleSort(headCell.id)}
                            >
                              {headCell.label}
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((item) => {
                          return (
                            <>
                              <TableRow>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{item.user_name}</TableCell>

                                <TableCell>{item.Contact_Us}</TableCell>

                                <TableCell>{item.coin}</TableCell>
                                <TableCell>{item.status}</TableCell>
                              </TableRow>
                            </>
                          );
                        })}
                    </TableBody>
                  </Table>
                  {(!filteredData || filteredData.length === 0) && (
          <div
            className="text-center p-3 "
            style={{
              display: loading ? "none" : "block",
              backgroundColor: "#f5f7ff",
            }}
          >
            {" "}
            No data available
          </div>
        )}
                </TableContainer>

                <TablePagination
                  component="div"
                  count={filteredData.length}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </div>

              <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <SnackbarContent
                  style={{ backgroundColor: "#f3797e", fontWeight: "700" }}
                  message={snackbarMessage}
                />
              </Snackbar>
            </div>
          </CustomTabPanel>
        </Box>
      </div>
    </>
  );
};
