import React,{useState,useEffect} from "react";
import style from "./Dashboard.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import { UserListing } from "../Users/UserListing";
import { getCollectionData } from '../../firebaseAuth/cloudFirestore/getData';
import { Box } from "@mui/system";
import { CircularProgress } from "@mui/material";



export const Dashboard = () => {

  
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  
  const navigate =useNavigate()
  
  const AdminCoin=()=>{
    navigate('admincoin')
  }

  const ActiveUsers=()=>{
    navigate('activeusers')
  }


  const getEvent = async () => {
    // setLoading(true)
    let data = await getCollectionData("Users");
  
  
    setData(data);
 
    console.log(data)
  };
  // console.log(data);
  

  const UserListing=()=>{
    navigate('userlisting')
  }

  const activeAndRealUsers = data.filter(user => user.isActive && user.isbot === false);
  const activeAndRealUsersCount = activeAndRealUsers.length;
  
  
  useEffect(() => {
    const fetchDataAndStopLoader = async () => {
      try {
        await getEvent();
      } finally {
        setLoading(false);
      }
    };

    fetchDataAndStopLoader();
  }, []);

  return (
    <>
     {loading ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay
          }}
        >
          <CircularProgress style={{ color: "#7DA0FA" }} />
        </Box>
      ) : null}

      <div>
        <div className={`p-2`}>
          <div className={`tableHeading mb-4`}><h4>Dashboard</h4></div>
          <div className={`d-flex justify-centent-between ${style.main_div}`}>

       
              <div className={`${style.main_card}`} onClick={AdminCoin}>
                <div className={`${style.icon_box}`}>
                  <img className={`${style.img_size}`} src="images/coin.png" alt="image"></img>
                </div>
                <div className={`${style.content_of_card}`}>
                  <div className={`${style.upper_content}`}>ADMIN COIN</div>
                  <div className={`${style.lower_content}`}>1,009</div>
                </div>
              </div>
       
           

            <div onClick={ActiveUsers} className={`${style.main_card}`}>
              <div className={`${style.icon_box}`}>
                <img className={`${style.img_size}`} src="images/artisan.png" alt="image"></img>
              </div>
              <div  className={`${style.content_of_card}`}>
                <div className={`${style.upper_content}`}>ACTIVE USER</div>
                <div className={`${style.lower_content}`}>{activeAndRealUsersCount}</div>
              </div>
            </div>

            <div className={`${style.main_card}`} onClick={UserListing}>
              <div className={`${style.icon_box}`}>
                <img className={`${style.img_size}`} src="images/customer.png" alt="image"></img>
              </div>
              <div className={`${style.content_of_card}`}>
                <div className={`${style.upper_content}`}>TOTAL USER</div>
                <div className={`${style.lower_content}`}>{data.length}</div>
              </div>
            </div>

            <div className={`${style.main_card}`}>
              <div className={`${style.icon_box}`}>
                <img className={`${style.img_size}`} src="images/money-bag.png" alt="image"></img>
              </div>
              <div className={`${style.content_of_card}`}>
                <div className={`${style.upper_content}`}>TOTAL PURCHASE</div>
                <div className={`${style.lower_content}`}>1,009</div>
              </div>
            </div>
            
          </div>
        </div>
      </div>

    </>
  );
};
